import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {inject, observer} from "mobx-react";
import Button from '@mui/material/Button';
import {useEffect, useState} from "react";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const AppHeaderBar = ({store}) => {

    const [inputValue, setInputValue] = useState('Exemplar Kreoss');
    const location = useLocation();
    const applicationSelectedPart = location.pathname.split('/')[1];

    useEffect(() => {
        store.application.parseUrl();
        // eslint-disable-next-line
    }, [location]);

    const navigate = useNavigate();
    const handleBack = () => {
        if (store.editions.selectedID && store.factions.selectedID && store.armies.selectedID && store.cards.selectedID) {
            navigate(`/${applicationSelectedPart}/${store.editions.selectedID}/${store.factions.selectedID}/${store.armies.selectedID}`);
        } else if (store.editions.selectedID && store.factions.selectedID && store.armies.selectedID) {
            navigate(`/${applicationSelectedPart}/${store.editions.selectedID}/${store.factions.selectedID}`);
        } else if (store.editions.selectedID && store.factions.selectedID) {
            navigate(`/${applicationSelectedPart}/${store.editions.selectedID}`);
        } else if (store.editions.selectedID) {
            navigate(`/${applicationSelectedPart}`);
        } else {
            navigate('/');
        }
    }


    const keyPress = (e) => {
        if(e.keyCode === 13){
            const searchResult = store.cards.getOneByName(e.target.value);
            if (searchResult) {
                let faction = store.factions.getByGuid(searchResult.factionId);
                let armies = faction.armies;
                store.editions.selectEdition(searchResult.editionId);
                store.factions.selectFaction(searchResult.factionId);
                armies.map(army => {
                    if (army.cards.some(e => e.guidStr === searchResult.guidStr)) {
                        store.armies.selectArmy(army.guidStr);
                        store.cards.selectCard(searchResult.guidStr);
                        navigate(`/cards/${searchResult.editionId}/${searchResult.factionId}/${army.guidStr}/${searchResult.guidStr}`);
                    }
                    return true;
                })
            }
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Button onClick={handleBack} className="bar-back-btn" variant="outlined">Back</Button>
                    <Breadcrumbs className="bar-breadcrumbs"  aria-label="breadcrumb">
                        <Link to="/">
                            Home
                        </Link>
                        <Link to={"/"+applicationSelectedPart}>
                            {applicationSelectedPart}
                        </Link>
                        {store.editions.selected && (
                        <Link to={"/"+applicationSelectedPart+"/"+store.editions.selectedID}>
                            {store.editions.selected.fullName}
                        </Link>
                        )}
                        {(store.editions.selected && store.factions.selected) && (
                            <Link to={"/"+applicationSelectedPart+"/"+store.editions.selectedID+"/"+store.factions.selectedID}>
                                {store.factions.selected.fullName}
                            </Link>
                        )}
                        {(store.editions.selected && store.factions.selected && store.armies.selected) && (
                            <Link to={"/"+applicationSelectedPart+"/"+store.editions.selectedID+"/"+store.factions.selectedID+"/"+store.armies.selectedID}>
                                {store.armies.selected.fullName}
                            </Link>
                        )}
                        {(store.editions.selected && store.factions.selected && store.armies.selected && store.cards.selected) && (
                            <Link to={"/"+applicationSelectedPart+"/"+store.editions.selectedID+"/"+store.factions.selectedID+"/"+store.armies.selectedID+"/"+store.cards.selectedID}>
                                {store.cards.selected.fullName}
                            </Link>
                        )}
                    </Breadcrumbs>
                    <Box sx={{ flexGrow: 1 }} />
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={inputValue}
                            onKeyDown={keyPress}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </Search>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default inject('store')(observer(AppHeaderBar));