import { types } from 'mobx-state-tree';
import KeywordModel from './keywordModel';

const KeywordsStore = types.model('KeywordsStore', {
    data: types.array(KeywordModel)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    }
}));

export default KeywordsStore;
