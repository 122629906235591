import React from 'react';
import {inject, observer} from "mobx-react";


import CardHeader from '../card/card-header';
import CardWeapons from '../card/card-weapons';
import CardHealth from "../card/card-health";
import CardOptions from "../card/card-options";
import CardAbilities from "../card/card-abilities";
import CardInfoPart from '../card/card-info-part';
import CardSpellPart from '../card/card-spells-part';
import CardWeaponAbilities from "./card-weapons-part";
import CardPictureReferences from "./card-picture-referencies";
import CardSpellRackPart from "./card-spell-options-part";

function isEven(n) {
    return /^-?\d*[02468]$/.test(n);
}

const CardMainBlock = ({
                           store,
                           printRef,
                           abilitiesRef,
                           spellsRef,
                           spellOptionsRef1,
                           spellOptionsRef2,
                           spellOptionsRef3,
                           weaponsRef,
                           cardWeaponsRef,
                           card,
                           model
                       }) => {

    let drawSpells = false;
    let drawSpellsOptions = false;
    let drawWeapons = false;
    let drawWeaponOptions = false;
    let separatePageForHealth = false;
    let separatePageForWeaponAbilities = false;
    let needReferencePage = false;
    let needBlankPage = false;


    let count = 1; //draw initial page form all
    count = 2; //draw abilities for all atm. But maybe will not draw for all.
    drawSpells = !!((0 < card.spells.length) && !card.animus);
    if (drawSpells) {
        count++
    }
    drawSpellsOptions = !!((card.faction.spells.length > 0) && (card.spellRackOptions.length > 0) && (card.needSpellRack));
    drawWeapons = !!(((store.application.abilitiesBlockSize + store.application.weaponsBlockSize) >= (store.application.maxInfoPageSize)) && (card.model && card.model.weapons))
    if (drawWeapons) {
        count++
    }
    drawWeaponOptions = (card.cardOption1Ids.length > 0);
    if (drawWeaponOptions) {
        count++
    }
    separatePageForWeaponAbilities = !!card.needNewPageForWeaponAbilities;
    if (separatePageForWeaponAbilities) {
        count++
    }
    separatePageForHealth = !!card.needNewPageForHealth;
    if (separatePageForHealth) {
        count++
    }
    needReferencePage = card.needSeparateHelperPage;
    if (needReferencePage) {
        count++
    }
    needBlankPage = !isEven(count);

    if (store.application.cardZoom) {
        return (
            <div id="art-station" ref={printRef} style={{
                transform: `scale(${store.application.cardZoom})`,
                MozTransform: `scale(${store.application.cardZoom})`,
                WebkitTransform: `scale(${store.application.cardZoom})`,
            }}>
                    <div className="card-content">
                        <div className="card-background card-background__first_page"/>
                        <div className="card-lens"/>
                        <div className="card-border"/>
                        <CardHeader card={card} model={model}/>
                        <CardWeapons card={card} model={model} cardWeaponsRef={cardWeaponsRef}/>
                        <CardHealth card={card} isHuge={false}/>
                        <CardAbilities card={card} model={model}/>
                    </div>
                    <div className="card-content">
                        <div className="card-background"/>
                        <div className="card-border-2"/>
                        <CardInfoPart card={card} model={model} abilitiesRef={abilitiesRef}
                                      weaponsRef={weaponsRef}/>
                    </div>
                    {drawWeaponOptions &&
                        <div className="card-content">
                            <div className="card-background"/>
                            <div className="card-border-2"/>
                            <CardOptions card={card}/>
                        </div>
                    }
                    {drawSpells &&
                        <div className="card-content">
                            <div className="card-background"/>
                            <div className="card-border-2"/>
                            <CardSpellPart spellsRef={spellsRef}/>
                        </div>
                    }
                    {drawSpellsOptions &&
                        <CardSpellRackPart refs={[spellOptionsRef1, spellOptionsRef2, spellOptionsRef3]}/>}
                    {drawWeapons &&
                        <div className="card-content">
                            <div className="card-background"/>
                            <div className="card-border-2"/>
                            <CardWeaponAbilities
                                card={card}
                                model={model}
                                weaponsRef={weaponsRef}
                                withWeapons={true}
                                withHelp={!card.needSeparateHelperPage}
                                withAbilities={!card.needNewPageForWeaponAbilities}
                            />
                        </div>
                    }
                    {separatePageForWeaponAbilities &&
                        <div className="card-content">
                            <div className="card-background"/>
                            <div className="card-border-2"/>
                            <CardWeaponAbilities
                                card={card}
                                model={model}
                                withWeapons={false}
                                withHelp={false}
                                withAbilities={true}
                                titleTxt="Weapon abilities"
                            />
                        </div>
                    }
                    {separatePageForHealth &&
                        <div className="card-content">
                            <div className="card-background"/>
                            <div className="card-border-2"/>
                            <CardHealth isHuge={true}/>
                        </div>
                    }
                    {needReferencePage &&
                        <div className="card-content">
                            <div className="card-background"/>
                            <div className="card-border-2"/>
                            <div className="card-info-part">
                                <div className="card-info-part-card-name">
                                    {card.fullName}{store.models.selected && ` - ${store.models.selected.fullName}`}
                                </div>
                                <CardPictureReferences card={card} model={model}/>
                            </div>
                        </div>
                    }
                    {needBlankPage &&
                        <div className="card-content">
                            <div className="card-background"/>
                            <div className="card-border-2"/>
                            <div className="card-info-part">
                                <div className="card-info-part-card-name">
                                    {card.fullName}{store.models.selected && ` - ${store.models.selected.fullName}`}
                                </div>
                                <div className="card-big-image-holder">
                                    <img className="card-image"
                                         src={card.portraitPath}
                                         alt={card.fullName}
                                    />
                                </div>
                            </div>
                        </div>
                    }
            </div>
        )
    } else {
        return (
            <div id="art-station" ref={printRef}>
                <div className="card-content">
                    <div className="card-background"/>
                </div>
            </div>
        )
    }
};

export default inject('store')(observer(CardMainBlock));
