import {getRoot, types} from 'mobx-state-tree';

const WeaponModel = types
    .model('WeaponModel', {
        deleted: types.boolean,
        guidStr: types.identifier,
        isLive: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string),
        statAOE: types.string,
        statPOW: types.string,
        statRNG: types.string,
        statROF: types.string,
        weaponAbilityIds: types.array(types.string),
        weaponAbilitySorting: types.array(types.string),
        weaponCount: types.integer,
        weaponLocation: types.string,
        weaponQualityIds: types.array(types.string),
        weaponType: types.string,
    })
    .views(self => ({
            get fullName() {
                    return self.name[0];
            },
            get qualities() {
                    let retArr = [];
                    self.weaponQualityIds.forEach(id => {
                            retArr.push(getRoot(self).weaponQualities.getByGuid(id));
                    });
                    retArr = retArr.sort((a, b) => a.displayPriority > b.displayPriority ? 1 : -1);
                return retArr;
            },
            get locations() {
                    if (self.weaponLocation.length > 0) {
                            const separators = ['&', ' and '];
                            return self.weaponLocation?.split(new RegExp(separators.join('|'), 'g'));
                    } else return null;
            },
            get abilities() {
                    let abilitiesArray = [];
                    self.weaponAbilitySorting.forEach(item => {
                            let id = item.split(':')[1];
                            abilitiesArray.push(getRoot(self).weaponAbilities.getByGuid(id));
                    })
                    return abilitiesArray;
            },
    }));

export default WeaponModel;