import { types } from 'mobx-state-tree';
import WeaponModel from './weaponModel';

const WeaponsStore = types.model('WeaponsStore', {
    data: types.array(WeaponModel)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    }
}));

export default WeaponsStore;
