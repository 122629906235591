import React from 'react';

const getAbilitiesStr = (option) => {

    let abilitiesStr = ""

    if (option.grantedAbilities.length > 0) {
        option.grantedAbilities.map((ability, index) => {
            if (index !== option.grantedAbilities.length - 1) {
                abilitiesStr += ability.fullName + ', '
            } else {
                abilitiesStr += ability.fullName
            }
            return true;
        })
    }

    if (option.grantedStatAdj.length > 0) {
        if (abilitiesStr !== "") {
            abilitiesStr += ', '
        }
        option.grantedStatAdj.map((statAdj, index) => {
            if (index !== option.grantedStatAdj.length - 1) {
                abilitiesStr += statAdj + ', '
            } else {
                abilitiesStr += statAdj
            }
            return true;
        })
    }

    if (option.weapon && (option.weapon.abilities.length > 0)) {
        if (abilitiesStr !== "") {
            abilitiesStr += ', '
        }
        option.weapon.abilities.map((ability, index) => {
            if (index !== option.weapon.abilities.length - 1) {
                abilitiesStr += ability.fullName + ', '
            } else {
                abilitiesStr += ability.fullName
            }
            return true;
        })
        return true;
    }

    if (option.spells.length > 0) {
        if (abilitiesStr !== "") {
            abilitiesStr += ', '
        }
        option.spells.map((spell, index) => {
            if (index !== option.spells.length - 1) {
                abilitiesStr += 'Spell:'+spell.fullName + ', '
            } else {
                abilitiesStr += 'Spell:'+spell.name[0]
            }
            return true;
        })
    }

    return abilitiesStr;
}

const QualitiesAndAdvantages = ({option}) => {
    let ret = [];
    if (option.grantedAdvantages.length > 0) {
        option.grantedAdvantages.map((advantage, index) => {
            ret.push(<div
                className={`card-option-${option.weapon ? 'weapon-' : ''}advantage`}
                key={Math.random()}>
                <img
                    className={`card-option-${option.weapon ? 'weapon-' : ''}advantage-img`}
                    src={advantage.iconPath}
                    alt={advantage.fullName}
                    title={advantage.fullName}
                />
            </div>)
            return true;
        })
    }
    if (option.weapon && (option.weapon.qualities.length > 0)) {
        option.weapon.qualities.map((quality) => {
            ret.push(<div
                className={`card-option-${option.weapon ? 'weapon-' : ''}advantage`}
                key={Math.random()}>
                <img
                    className={`card-option-${option.weapon ? 'weapon-' : ''}advantage-img`}
                    src={quality.iconPath}
                    alt={quality.fullName}
                    title={quality.fullName}
                />
            </div>);
            return true;
        })
    }

    return (
        <div className={`card-option-${option.weapon ? 'weapon-' : ''}advantages`}>
            {ret}
        </div>
    )

}

const CardOptionGroup = ({card, groupNumber, name}) => {

    return (
        <div className={`card-options-group card-options-group-${groupNumber}`}>
            <div className="card-options-group-name">
                {name}
            </div>
            <div className="card-options-group-options">
                {card.options(groupNumber).map((option, index) => (
                    <div className="card-option" key={index}>
                        <div
                            className={`card-option-name ${option.weapon ? 'card-option-' + option.weapon.weaponType.toLowerCase() + '-name' : ""}`}>
                            {option.fullName}
                            <div className={`card-option-advantages-${option.weapon ? 'weapon-' : ''}holder`}>
                                <QualitiesAndAdvantages option={option} />
                            </div>
                        </div>
                        {option.portraitPath &&
                            <img className="card-option-portrait" src={option.portraitPath} alt={option.fullName}/>
                        }
                        <div className="card-option-cost">
                            {option.pointCost}
                        </div>
                        {option.weapon &&
                            <div
                                className={`card-option-${option.weapon.weaponType.toLowerCase()}-weapon-stats-holder`}>
                                <div className="card-option-weapon-stat">
                                    <div className="card-option-weapon-stat-name">
                                        RNG
                                    </div>
                                    <div className="card-option-weapon-stat-value">
                                        {option.weapon.statRNG}
                                    </div>
                                </div>
                                {(option.weapon.weaponType === "Ranged") && (
                                    <>
                                        <div className="card-option-weapon-stat">
                                            <div className="card-option-weapon-stat-name">
                                                ROF
                                            </div>
                                            <div className="card-option-weapon-stat-value">
                                                {option.weapon.statROF}
                                            </div>
                                        </div>
                                        <div className="card-option-weapon-stat">
                                            <div className="card-option-weapon-stat-name">
                                                AOE
                                            </div>
                                            <div className="card-option-weapon-stat-value">
                                                {option.weapon.statAOE}
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="card-option-weapon-stat">
                                    <div className="card-option-weapon-stat-name">
                                        POW
                                    </div>
                                    <div className="card-option-weapon-stat-value">
                                        {option.weapon.statPOW}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="card-option-weapon-abilities-holder">
                            {getAbilitiesStr(option)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CardOptionGroup