import {types} from 'mobx-state-tree';

const SpellModel = types
    .model('SpellModel', {
        deleted: types.boolean,
        description: types.maybeNull(types.array(types.string)),
        guidStr: types.identifier,
        helpTermIds: types.maybeNull(types.array(types.string)),
        isLive: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string),
        statAOE: types.maybeNull(types.string),
        statCOST: types.maybeNull(types.string),
        statDUR: types.maybeNull(types.string),
        statOFF: types.maybeNull(types.string),
        statPOW: types.maybeNull(types.string),
        statRNG: types.maybeNull(types.string),
    }).views(self => ({
        // get fullName() {
        //     return self.name[0];
        // },
    }));

export default SpellModel;

