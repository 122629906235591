import {types, getRoot} from 'mobx-state-tree';

const CardModel = types
    .model('CardModel', {
        guidStr: types.identifier,
        animosityIds: types.maybeNull(types.array(types.string)),
        animosityKeywordsIds: types.maybeNull(types.array(types.string)),
        cardAbilityIds: types.maybeNull(types.array(types.string)),
        cardAbilitySorting: types.maybeNull(types.array(types.string)),
        cardOption1Count: types.string,
        cardOption1Ids: types.maybeNull(types.array(types.string)),
        cardOption1Name: types.maybeNull(types.array(types.string)),
        cardOption2Count: types.string,
        cardOption2Ids: types.maybeNull(types.array(types.string)),
        cardOption2Name: types.maybeNull(types.array(types.string)),
        cardOption3Count: types.string,
        cardOption3Ids: types.maybeNull(types.array(types.string)),
        cardOption3Name: types.maybeNull(types.array(types.string)),
        cardOption4Count: types.string,
        cardOption4Ids: types.maybeNull(types.array(types.string)),
        cardOption4Name: types.maybeNull(types.array(types.string)),
        cardOption5Count: types.string,
        cardOption5Ids: types.maybeNull(types.array(types.string)),
        cardOption5Name: types.maybeNull(types.array(types.string)),
        cardOption6Count: types.string,
        cardOption6Ids: types.maybeNull(types.array(types.string)),
        cardOption6Name: types.maybeNull(types.array(types.string)),
        cardTypeId: types.string,
        characterAltIds: types.maybeNull(types.array(types.string)),
        companionIds: types.maybeNull(types.array(types.string)),
        editionId: types.string,
        factionId: types.string,
        featDescription: types.maybeNull(types.array(types.string)),
        featName: types.maybeNull(types.array(types.string)),
        fieldAllowance: types.string,
        forceField: types.string,
        healthName1: types.maybeNull(types.array(types.string)),
        healthName2: types.maybeNull(types.array(types.string)),
        healthName3: types.maybeNull(types.array(types.string)),
        healthName4: types.maybeNull(types.array(types.string)),
        healthName5: types.maybeNull(types.array(types.string)),
        healthName6: types.maybeNull(types.array(types.string)),
        healthName7: types.maybeNull(types.array(types.string)),
        healthName8: types.maybeNull(types.array(types.string)),
        healthType: types.string,
        healthValue1: types.string,
        healthValue2: types.string,
        healthValue3: types.string,
        healthValue4: types.string,
        healthValue5: types.string,
        healthValue6: types.string,
        healthValue7: types.string,
        healthValue8: types.string,
        hireFacIds: types.maybeNull(types.array(types.string)),
        isLive: types.boolean,
        keywordsIds: types.maybeNull(types.array(types.string)),
        modelIds: types.array(types.string),
        modelSorting: types.maybeNull(types.array(types.string)),
        name: types.maybeNull(types.array(types.string)),
        pointCost: types.string,
        pointCostDescription: types.maybeNull(types.array(types.string)),
        portraitPath: types.string,
        reqSub: types.boolean,
        requiredAttachmentCount: types.integer,
        requiredAttachmentIds: types.maybeNull(types.array(types.string)),
        spellIds: types.maybeNull(types.array(types.string)),
        spellRackOptions: types.string,
        spellSorting: types.maybeNull(types.array(types.string)),
        // spellsPageDivider: types.maybeNull(types.number),
        summonIds: types.maybeNull(types.array(types.string)),
        validAttachmentIds: types.maybeNull(types.array(types.string)),
        needSeparateHealthPage: false,
        needSeparateHelperPage: false,
        needNewPageForWeaponAbilities: false,
        needSpellRack: false,
    })
    .actions(self => ({
        setNeedSeparateHealth(i) {
            self.needSeparateHealthPage = i;
        },
        setHelperOnSeparatePage(i) {
            self.needSeparateHelperPage = i;
        },
        setNeedNewPageForWeaponAbilities(i) {
            self.needNewPageForWeaponAbilities = i;
        },
        toggleSpellRack() {
            self.needSpellRack = !self.needSpellRack;
        },
        toggleSeparateHealth() {
            self.needSeparateHealthPage = !self.needSeparateHealthPage;
        },
    }))
    .views(self => ({
        get model() {
            if (self.modelIds.length<2) {
                return getRoot(self).models.getByGuid(self.modelIds[0]);
            } else return null; 
        },
        get fullName() {
            let ret = "";
            self.name.forEach((item) => {
                ret += item + ' ';
            });
            return ret.trim();
        },
        get faction() {
            return getRoot(self).factions.getByGuid(self.factionId);
        },
        get cardType() {
            return getRoot(self).cardTypes.getByGuid(self.cardTypeId);
        },
        get keywordsArray() {
            let retArr = [];
            let cardFactionKyewordId = "";
            if (self.keywordsIds) {
                //search for a faction keyword
                let tmpArr = [];
                self.keywordsIds.forEach(keywordId => {
                    let testSTr = getRoot(self).keywords.getByGuid(keywordId).fullName;
                    if (testSTr.includes(self.faction.shortName)) {
                        retArr.push(getRoot(self).keywords.getByGuid(keywordId));
                    } else {
                        tmpArr.push(keywordId)
                    }
                });
                //search for all but the card type ( card type is the final )
                tmpArr.forEach(keywordId => {
                    if (!getRoot(self).keywords.getByGuid(keywordId).fullName.includes(self.cardType.fullName)) {
                        retArr.push(getRoot(self).keywords.getByGuid(keywordId));
                    } else {
                        cardFactionKyewordId = keywordId;
                    }
                });
                //add model type keyword
                if (cardFactionKyewordId) {
                    retArr.push(getRoot(self).keywords.getByGuid(cardFactionKyewordId))
                }
            }
            return retArr;
        },
        get keywords() {
            let ret = "";
            self.keywordsArray.forEach((item) => {
                ret += item.fullName + ' ';
            });
            return ret.trim();
        },
        get allOptions() {
            let retArr = [];
            self.cardOption1Ids.forEach(optionId => {
                retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
            });
            self.cardOption2Ids.forEach(optionId => {
                retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
            });
            self.cardOption3Ids.forEach(optionId => {
                retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
            });
            self.cardOption4Ids.forEach(optionId => {
                retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
            });
            self.cardOption5Ids.forEach(optionId => {
                retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
            });
            self.cardOption6Ids.forEach(optionId => {
                retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
            });
            return retArr;
        },
        options(optionNumber) {
            let retArr = [];
            switch (optionNumber) {
                case 1:
                default:
                    self.cardOption1Ids.forEach(optionId => {
                        retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
                    });
                    break;
                case 2:
                    self.cardOption2Ids.forEach(optionId => {
                        retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
                    });
                    break;
                case 3:
                    self.cardOption3Ids.forEach(optionId => {
                        retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
                    });
                    break;
                case 4:
                    self.cardOption4Ids.forEach(optionId => {
                        retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
                    });
                    break;
                case 5:
                    self.cardOption5Ids.forEach(optionId => {
                        retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
                    });
                    break;
                case 6:
                    self.cardOption6Ids.forEach(optionId => {
                        retArr.push(getRoot(self).cardOptions.getByGuid(optionId))
                    });
                    break;
            }
            retArr.sort((a, b) => {
                if (a.fullName < b.fullName) return -1;
                if (a.fullName > b.fullName) return 1;
                return 0;
            });
            return retArr;
        },
        healthBlockName(optionNumber) {
            let ret = "";
            switch (optionNumber) {
                case 1:
                default:
                    self.healthName1.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
                case 2:
                    self.healthName2.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
                case 3:
                    self.healthName3.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
                case 4:
                    self.healthName4.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
                case 5:
                    self.healthName5.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
                case 6:
                    self.healthName6.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
                case 7:
                    self.healthName7.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
                case 8:
                    self.healthName8.forEach((item) => {
                        ret += item + ' ';
                    });
                    break;
            }
            return ret;
        },
        health(optionNumber) {
            switch (optionNumber) {
                case 1:
                default:
                    return parseInt(self.healthValue1);
                case 2:
                    return parseInt(self.healthValue2);
                case 3:
                    return parseInt(self.healthValue3);
                case 4:
                    return parseInt(self.healthValue4);
                case 5:
                    return parseInt(self.healthValue5);
                case 6:
                    return parseInt(self.healthValue6);
                case 7:
                    return parseInt(self.healthValue7);
                case 8:
                    return parseInt(self.healthValue8);
            }
        },
        get feat() {
            return (self.featName) ?  self.featName[0] : null;
        },
        get spells() {
            const spellsArray = [];
            self.spellSorting.forEach(item => {
                let id = item.split(':')[1];
                getRoot(self).spells.getByGuid(id) && spellsArray.push(getRoot(self).spells.getByGuid(id));
            })
            self.allOptions.forEach(option => {
                option.spells.forEach(spell => {
                    spellsArray.push(spell);
                })
            })
            return spellsArray;
        },
        get normalizedPointCost() {
            let pc = 0;
            if (parseInt(self.pointCost) > 0) {
                pc = parseInt(self.pointCost);
            }
            return pc;
        },
        get animus () {
            let animus = null;
            if ((self.cardType.fullName === "Warbeast") && (self.spells.length === 1)) {
                animus = self.spells[0];
            }
            return animus;
        },
        get maxHealth() {
            let mh = 0;
            if (parseInt(self.healthValue1) > 0) {
                mh  += parseInt(self.healthValue1);
            }
            if (parseInt(self.healthValue2) > 0) {
                mh  += parseInt(self.healthValue2);
            }
            if (parseInt(self.healthValue3) > 0) {
                mh  += parseInt(self.healthValue3);
            }
            if (parseInt(self.healthValue4) > 0) {
                mh  += parseInt(self.healthValue4);
            }
            if (parseInt(self.healthValue5) > 0) {
                mh  += parseInt(self.healthValue5);
            }
            if (parseInt(self.healthValue6) > 0) {
                mh  += parseInt(self.healthValue6);
            }
            if (parseInt(self.healthValue7) > 0) {
                mh  += parseInt(self.healthValue7);
            }
            if (parseInt(self.healthValue8) > 0) {
                mh  += parseInt(self.healthValue8);
            }
            if ((mh === 0) && (self.healthValue1.includes(','))) {
                (self.healthValue1.length > 1) && self.healthValue1.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
                (self.healthValue2.length > 1) && self.healthValue2.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
                (self.healthValue3.length > 1) && self.healthValue3.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
                (self.healthValue4.length > 1) && self.healthValue4.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
                (self.healthValue5.length > 1) && self.healthValue5.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
                (self.healthValue6.length > 1) && self.healthValue6.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
                (self.healthValue7.length > 1) && self.healthValue7.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
                (self.healthValue8.length > 1) && self.healthValue8.split(',').forEach((element) => {
                    if (element !== '-') {mh++;}
                });
            }
            return mh;
        },
        get needNewPageForHealth() {
            if (self.needSeparateHealthPage || ["Spiral Large","Grid Large"].includes(self.healthType)) {
                return true;
            } else {
                return false;
            }
        },
        get companions() {
            const retArr = [];
            let uniqueArr = [];
            if (self.companionIds.length) {
                self.companionIds.map((cardId) => {
                    if (!uniqueArr.includes(cardId)) {
                        uniqueArr.push(cardId);
                        let foundCompanion = getRoot(self).cards.getByGuid(cardId);
                        if (foundCompanion) { retArr.push(foundCompanion)}
                    }
                    return true;
                })
            }
            return retArr;
        },
        //in some units for unknown reason there is a multiple models.
        get models() {
            const retArr = [];
            let uniqueArr = [];
            self.modelIds.map((id) => {
                if (!uniqueArr.includes(id)) {
                    uniqueArr.push(id);
                    let foundModel = getRoot(self).models.getByGuid(id);
                    retArr.push(foundModel);
                }
                return true;
            });
            return retArr;
        },
        //for even more unknown reason in some units same models are stored in options ag granted models.
        get grantedModels() {
            const retArr = [];
            let uniqueArr = [];
            let uniqueArr2 = [];
            for (let i = 1; i < 7; i++) {
                if (self['cardOption'+i+'Ids'].length) {
                    self['cardOption'+i+'Ids'].map((id) => {
                        if (!uniqueArr.includes(id)) {
                            uniqueArr.push(id);
                            let option = getRoot(self).cardOptions.getByGuid(id);
                            if (option && option.grantedModelIds.length) {
                                option.grantedModelIds.map((id2) => {
                                    if (!uniqueArr2.includes(id2)) {
                                        uniqueArr2.push(id2);
                                        let foundModel = getRoot(self).models.getByGuid(id2);
                                        retArr.push(foundModel);
                                    }
                                    return true;
                                });
                            }
                        }
                        return true;
                    })
                }
            }
            return retArr;
        },
    }));

export default CardModel;
