import React from 'react';

const CardSimpleOptionGroup = ({card,groupNumber,name}) => {

    return (
                <div className={`card-simple-options-group card-simple-options-group-${groupNumber}`}>
                    <div className="card-simple-options-group-name">
                        {name}
                    </div>
                    <div className="card-simple-options-group-options">
                        {card.options(groupNumber).map((option, index) => (
                            <div className="card-simple-option" key={index}>
                                <div className="card-simple-option-picker" />
                                <div
                                    className={`card-simple-option-name ${option.weapon ? 'card-simple-option-' + option.weapon.weaponType.toLowerCase() + '-name' : ""}`}>
                                    {option.fullName}
                                </div>
                                <div className="card-simple-option-cost">
                                    {option.pointCost}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
    )
}

export default CardSimpleOptionGroup