import React, {} from 'react';
import {inject, observer} from "mobx-react";

window._smallerValueOfCirclesGrid = 0;
const SpiralHealthBlocks = ({isHuge, store}) => {

    if (store.cards.selected) {
        const generateSpiralCoords = (startAngle = 0, startX, startY, dist, num) => {
            const angle = (startAngle + num) * 0.3;
            const distance = dist * (num / 10);
            const x = startX + Math.cos(angle) * distance;
            if ((x < (maxW / 2)) && (x < window._smallerValueOfCirclesGrid)) {
                window._smallerValueOfCirclesGrid = x;
            }
            if ((x > (maxW / 2)) && (((maxW - ballSize) - x) < window._smallerValueOfCirclesGrid)) {
                window._smallerValueOfCirclesGrid = (maxW - ballSize) - x;
            }
            const y = startY + Math.sin(angle) * distance;
            if ((y < ((maxW / 2) - ballSize)) && (y < window._smallerValueOfCirclesGrid)) {
                window._smallerValueOfCirclesGrid = y;
            }
            if ((y > (maxW / 2)) && (((maxW - ballSize) - y) < window._smallerValueOfCirclesGrid)) {
                window._smallerValueOfCirclesGrid = (maxW - ballSize) - 20 - y;
            }
            return {x: x, y: y};
        }

        const generateCircle = (n, type, coords, circles, digit, sizeIncFactor, isActive) => {
            if (!digit) {
                circles.push(<div
                    className={`spiral-div spiral-div__${type} spiral-div__${n} ${!isActive ? "spiral-div__inactive" : ""}`}
                    key={Math.random()}
                    style={{
                        transform: `scale(${1 + (n / sizeIncFactor)})`,
                        MozTransform: `scale(${1 + (n / sizeIncFactor)})`,
                        WebkitTransform: `scale(${1 + (n / sizeIncFactor)})`,
                        left: `${coords.x}px`,
                        top: `${coords.y}px`
                    }}
                />)
                circles.push(<div
                    className={`spiral-div spiral-div__${type}_shadow spiral-div__${n} ${!isActive ? "spiral-div__inactive" : ""}`}
                    key={Math.random()}
                    style={{
                        transform: `scale(${1 + (n / sizeIncFactor)})`,
                        MozTransform: `scale(${1 + (n / sizeIncFactor)})`,
                        WebkitTransform: `scale(${1 + (n / sizeIncFactor)})`,
                        left: `${coords.x}px`,
                        top: `${coords.y}px`
                    }}
                />)
            } else {
                circles.push(<div className={`spiral-div spiral-div__${type}_digit`}
                                  key={Math.random()}
                                  style={{
                                      transform: `scale(${1 + (n / sizeIncFactor)})`,
                                      MozTransform: `scale(${1 + (n / sizeIncFactor)})`,
                                      WebkitTransform: `scale(${1 + (n / sizeIncFactor)})`,
                                      left: `${coords.x}px`,
                                      top: `${coords.y}px`
                                  }}
                >{digit}</div>)
            }
            return circles;
        }


        const drawOneAspect = (type, subBranchNumber) => {
            circles = generateCircle(0, type, generateSpiralCoords(angles[type] + 1, centerX, centerY, distanceIncrement, 1), circles, null, sFactor, true);
            circles = generateCircle(1, type, generateSpiralCoords(angles[type] + 1, centerX, centerY, distanceIncrement, 2), circles, null, sFactor, true);
            //1
            subBranchNumber++;
            health[subBranchNumber] = Math.ceil((hv[type] - 2) / 2);
            // for (let i = 2; i < health[subBranchNumber] + 2; i++) {
            for (let i = 2; i < health[subBranchNumber] + 3; i++) {
                circles = generateCircle(i, type, generateSpiralCoords(angles[type], centerX, centerY, distanceIncrement, i + 1), circles, (i === (health[subBranchNumber] + 2)) ? subBranchNumber : null, sFactor, (i <= health[subBranchNumber] + 2));
            }
            //2
            subBranchNumber++;
            health[subBranchNumber] = (hv[type] - 2) - health[subBranchNumber - 1];
            // for (let i = 2; i < health[subBranchNumber] + 2; i++) {
            for (let i = 2; i < health[subBranchNumber] + 3; i++) {
                circles = generateCircle(i, type, generateSpiralCoords(angles[type] + 2, centerX, centerY, distanceIncrement, i + 1), circles, (i === (health[subBranchNumber] + 2)) ? subBranchNumber : null, sFactor, (i <= health[subBranchNumber] + 2));
            }
        }

        window._largestMinus = 0;
        let maxW = store.application.isMobile ? 100 : 650;
        let ballSize = store.application.isMobile ? 10: 50;
        let sFactor = store.application.isMobile ? 5 : 10;

        if (isHuge) {
            maxW = store.application.isMobile ? 200 : 1200;
            sFactor =  store.application.isMobile ? 5 : 5;
        }

        // const biggestHealth = Math.ceil((Math.max(store.cards.selected.healthValue1, store.cards.selected.healthValue2, store.cards.selected.healthValue3) - 2) / 2);

        const centerX = maxW / 2;
        const centerY = maxW / 2;
        let branchCenter = {};
        branchCenter.x = maxW / 2;
        branchCenter.y = maxW / 2;
        const distanceIncrement = maxW - ballSize; // Distance between divs
        const hv = {};
        hv.mind = parseInt(store.cards.selected.healthValue1);
        hv.body = parseInt(store.cards.selected.healthValue2);
        hv.spirit = parseInt(store.cards.selected.healthValue3);
        const health = {};
        const angles = {
            mind: 5,
            body: 12,
            spirit: 19,
        }


        let circles = [];
        //mind
        //first 2 circles
        drawOneAspect("mind", 0);
        drawOneAspect("body", 2);
        drawOneAspect("spirit", 4);

        let zoomFactor = (centerX / (centerX - window._smallerValueOfCirclesGrid));

        return (
            <div className="card-health-spiral-box-container">
                <div className="card-health-spiral-box-zoomable" style={{
                    transform: `scale(${zoomFactor})`,
                    MozTransform: `scale(${zoomFactor})`,
                    WebkitTransform: `scale(${zoomFactor})`,
                }}>
                    {circles}
                </div>
            </div>
        )
    } else return null;
}

export default inject('store')(observer(SpiralHealthBlocks));
