import {types} from 'mobx-state-tree';


const AbilityModel = types
    .model('AbilityModel', {
        deleted: types.boolean,
        description: types.maybeNull(types.array(types.string)),
        guidStr: types.identifier,
        helpTermIds: types.maybeNull(types.array(types.string)),
        isLive: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string)
    }).views(self => ({
        get fullName() {
            return self.name[0];
        },
    }));

export default AbilityModel;

