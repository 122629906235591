import React from 'react';
import {inject, observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import CardStats from '../card/card-stats';



const CardInMenu = ({store, card, additionalClass=null}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const applicationSelectedPart = location.pathname.split('/')[1];
    const classGenerator = (card) => {
        let classnameVar = 'menu-card-model-info';
        if (card.guidStr === store.cards.selectedID) {
            classnameVar += ' menu-card-model-info_active';
        }
        if (store.armies.selected.includedCardIds.includes(card.guidStr)) {
            classnameVar += ' menu-card-model-info_mercenary';
        }
        if (additionalClass) {
            classnameVar += ' '+additionalClass;
        }
        return classnameVar;
    }

    return (
        <div className={classGenerator(card)}
             onClick={() => {
                 navigate(`/${applicationSelectedPart}/${store.editions.selectedID}/${store.factions.selectedID}/${store.armies.selectedID}/${card.guidStr}`);
             }
             }
        >
            <div className="menu-card-model-img-holder">
                <img className="menu-card-model-img"
                     src={card.portraitPath}
                     alt={card.fullName}
                />
            </div>
            <div className="menu-card-model-summary">
                <div className="menu-card-model-name">
                    {card.fullName}
                </div>
                {card.model && <CardStats model={card.model}/>}
            </div>
            <div className="menu-card-model-pointcost">
                <span className="menu-card-model-stat-name">PC:</span>{card.normalizedPointCost}
            </div>
            <div className="menu-card-model-field-allowance">
                <span className="menu-card-model-stat-name">FA:</span>{card.fieldAllowance}
            </div>
        </div>
    );

};

export default inject('store')(observer(CardInMenu));
