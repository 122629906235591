import {types} from 'mobx-state-tree';

const SpellCardModel = types
    .model('SpellCardModel', {
        guidStr: types.identifier,
        name: types.array(types.string),
            isLive: types.maybeNull(types.boolean),
            metadata: types.maybeNull(types.string),
            deleted: types.maybeNull(types.boolean),
            reqSub: types.maybeNull(types.boolean),
            devNotes: types.maybeNull(types.string),
            dataBundleId: types.maybeNull(types.string),
            editionId: types.maybeNull(types.string),
            factionId: types.maybeNull(types.string),
            armyId: types.maybeNull(types.string),
            spellId: types.maybeNull(types.string)
    }).views(self => ({
        // get fullName() {
        //     return self.name[0];
        // },
    }));

export default SpellCardModel;

