import React from 'react';
import CardStats from "./card-stats";
import {inject, observer} from "mobx-react";

const CardHeader = ({store, card, model}) => {

    return (
        <>
            <div className="card-image-holder">
                <img className="card-image"
                     src={card.portraitPath}
                     alt={card.fullName}
                />
            </div>
            <div className="card-name-holder">
                <p className="card-name">
                    {card.fullName}{store.models.selected && ` - ${store.models.selected.fullName}`}
                </p>
            </div>
            <div className="card-keywords-holder">
                <p className="card-keywords">
                    {card.keywords}
                </p>
            </div>
            <div className="card-faction-logo-holder">
                <img
                    className="card-faction-logo"
                    src={card.faction.iconColorPath}
                    alt={card.faction.fullName}
                />
            </div>
            <div className="card-short-name-holder">
                {model.fullName}
            </div>
            <CardStats model={model}/>
            <div className="card-advantages-holder">
                {model.advantages && model.advantages.map((advantage, index) => (
                    <div className="card-advantage" key={index}>
                        <img className="card-advantage-img"
                             src={advantage.iconPath}
                             alt={advantage.fullName}
                             title={advantage.fullName}
                        />
                    </div>
                ))}
            </div>
            <div className="card-pointcost-title">
                PC
            </div>
            <div className="card-pointcost">
                {card.normalizedPointCost}
            </div>
            <div className="card-field-allowance-title">
                FA
            </div>
            <div className="card-field-allowance">
                {card.fieldAllowance}
            </div>
        </>
    );
};

export default inject('store')(observer(CardHeader));
