import {types} from 'mobx-state-tree';

const AdvantageModel = types
    .model('AdvantageModel', {
        deleted: types.boolean,
        displayPriority: types.integer,
        guidStr: types.identifier,
        helpTermIds: types.maybeNull(types.array(types.string)),
        iconPath: types.string,
        isLive: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string)
    }).views(self => ({
            get fullName() {
                return self.name[0].replace('z_', 'Base size: ');
            },
    }));

export default AdvantageModel;

