import React from 'react';
import CardOptions from "./card-options";
import CardWeaponAbilities from './card-weapon-abilities';
import {inject, observer} from "mobx-react";


const CardWeapons = ({cardWeaponsRef, store, card, model}) => {

    return (
        <div className="card-weapons-holder"
             id="card-weapons-holder"
             ref={cardWeaponsRef}
             style={{fontSize: `${store.application.cardWeaponsTextSize2}px`}}
        >
            {!card.cardOption1Ids.length && model.weapons.map((item, index) => (
                <div key={index} className={`card-weapon card-weapon__${index}`}>
                    <div className="card-weapon-background"/>
                    <div className={`card-weapon-type-${item.weaponType.toLowerCase()}`}/>
                    <div className="card-weapon-name">{item.fullName}</div>
                    <div className="card-weapon-stats-holder">
                        <div className="card-weapon-stat">
                            <div className="card-weapon-stat-name">
                                RNG
                            </div>
                            <div className="card-weapon-stat-value">
                                {item.statRNG}
                            </div>
                        </div>
                        {(item.weaponType === "Ranged") && (
                            <>
                                <div className="card-weapon-stat">
                                    <div className="card-weapon-stat-name">
                                        ROF
                                    </div>
                                    <div className="card-weapon-stat-value">
                                        {item.statROF}
                                    </div>
                                </div>
                                <div className="card-weapon-stat">
                                    <div className="card-weapon-stat-name">
                                        AOE
                                    </div>
                                    <div className="card-weapon-stat-value">
                                        {item.statAOE}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="card-weapon-stat">
                            <div className="card-weapon-stat-name">
                                POW
                            </div>
                            <div className="card-weapon-stat-value">
                                {item.statPOW}
                            </div>
                        </div>
                    </div>
                    <div className="card-weapon-qualities-holder">
                        {item.qualities.map((quality, index) => (
                            <div className="card-weapon-quality" key={index}>
                                <img className="card-weapon-quality-img"
                                     src={quality.iconPath}
                                     alt={quality.fullName}
                                     title={quality.fullName}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="card-weapon-locations-holder">
                        {item.locations && item.locations.map((location, index) => (
                            <div className="card-weapon-location" key={index}>
                                {location}
                            </div>
                        ))}
                    </div>
                    {(item.weaponCount > 1) && (
                        <div className="card-weapon-count">
                            x{item.weaponCount}
                        </div>
                    )}
                    <CardWeaponAbilities
                        item={item}
                        card={card}
                        model={model}
                        withWeapons={true}
                        withHelp={!card.needSeparateHelperPage}
                        withAbilities={!card.needNewPageForWeaponAbilities}
                    />
                </div>
            ))}
            {card.cardOption1Ids.length ?
                <CardOptions card={card} isSimple={true}/>
                : null}
        </div>
    );
}

export default inject('store')(observer(CardWeapons));