import React, {} from 'react';
import HealthBlocks from "./health-blocks";


//For units with more than 1 models with a healthBars
const MultyHealthBlocks = ({card}) => {
    const blocks = [];
    for (let i = 1; i < 9; i++) {
        if (card.health(i)) {
            blocks.push(
                <div key={i}>
                    <p className="card-health-multi-box-block-name">{card.healthBlockName(i)}</p>
                    <HealthBlocks number={card.health(i)} withInactive={false}/>
                </div>);
        }
    }
    return (
        <div className="card-health-multi-box-container">
            {blocks}
        </div>
    );
};
export default MultyHealthBlocks;
