import React from 'react';
import { inject, observer } from "mobx-react";
import Button from '@mui/material/Button';
import loadingImg from "../../static/images/loading.gif";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const CardPreview = ({ store, handleSave, loading, closePopup  }) => {


    return (
        <div className="app-body-preview-part">
            {loading && <div className="app-body-preview-part-loading">
                <img src={loadingImg} alt="loading"/>
                <p>Image is generating, please wait.</p>
            </div>}
            {store.cards.selected && (
                <div id="cardRef" className="card card-zoomed">
                    {/*here be dragons!*/}
                </div>
            )}
            {!loading &&
                <div className="modal-footer">
                    <Button onClick={handleSave} variant="contained" startIcon={<SaveIcon />}>Save image</Button>
                    <Button onClick={closePopup} variant="contained" startIcon={<CloseIcon />}>Close</Button>
                </div>
            }
        </div>
    );
};

export default inject('store')(observer(CardPreview));
