import React from 'react';

const CardWeaponAbilities = ({item, model}) => {

    if (item.abilities.length < 1) {
        return null;
    } else if (model.weapons.length > 3) {
        return (
            <div className="card-weapon-abilities-holder">
                <div className="card-weapon-abilities-inner card-weapon-abilities-inner-smaller">
                    {item.abilities.map((ability, index) => {
                        if (index!==item.abilities.length-1) {
                            return (ability.fullName)+', '
                        } else {
                            return ability.fullName
                        }
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div className="card-weapon-abilities-holder">
                <div className="card-weapon-abilities-inner">
                    {item.abilities.map((ability, index) => (
                        <div className="card-ability" key={index}>
                            {ability.fullName}
                        </div>
                    ))
                    }
                </div>
            </div>
        )
    }
}


export default CardWeaponAbilities