import React, {} from 'react';


//For grids and large grids ( warjacks );
const HealthGrid = ({card}) => {
    return (
        <>
            {(!!card.healthValue1) && (
                <div className="card-health-grid">
                    <div className="card-health-grid-title">
                        DAMAGE
                    </div>
                    <div className="card-health-grid-line">
                        {card.healthValue1.split(',').map((item, index) => (
                            <div key={index} className="card-health-grid-box-numbers">
                                {((index + 1) < 7) ? (index + 1) : (index - 5)}
                            </div>
                        ))}
                    </div>
                    {(!!card.healthValue1) && (
                        <div className="card-health-grid-line">
                            {card.healthValue1.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {(!!card.healthValue2) && (
                        <div className="card-health-grid-line">
                            {card.healthValue2.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {(!!card.healthValue3) && (
                        <div className="card-health-grid-line">
                            {card.healthValue3.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {(!!card.healthValue4) && (
                        <div className="card-health-grid-line">
                            {card.healthValue4.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {(!!card.healthValue5) && (
                        <div className="card-health-grid-line">
                            {card.healthValue5.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {(!!card.healthValue6) && (
                        <div className="card-health-grid-line">
                            {card.healthValue6.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {(!!card.healthValue7) && (
                        <div className="card-health-grid-line">
                            {card.healthValue7.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {(!!card.healthValue8) && (
                        <div className="card-health-grid-line">
                            {card.healthValue8.split(',').map((item, index) => (
                                <div key={index}
                                     className={`card-health-grid-box${(item === "-") ? '-inactive' : ''}`}>
                                    <div className="card-health-grid-box__text">{item}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    )
};

export default HealthGrid;
