import React from 'react';
const CardStats = ({model}) => {

    return (
            <div className="card-stats-holder">
                {!!model.statSPD && (
                    <div className="card-stat" title="Speed">
                        <div className="card-stat-name">
                            SPD
                        </div>
                        <div className="card-stat-value">
                            {model.statSPD}
                        </div>
                    </div>
                )}
                {!!model.statAAT && (
                    <div className="card-stat" title="Arcane">
                        <div className="card-stat-name">
                            AAT
                        </div>
                        <div className="card-stat-value">
                            {model.statAAT}
                        </div>
                    </div>
                )}
                {!!model.statMAT && (
                    <div className="card-stat" title="Melee Attack">
                        <div className="card-stat-name">
                            MAT
                        </div>
                        <div className="card-stat-value">
                            {model.statMAT}
                        </div>
                    </div>
                )}
                {!!model.statRAT && (
                    <div className="card-stat" title="Ranged Attack">
                        <div className="card-stat-name">
                            RAT
                        </div>
                        <div className="card-stat-value">
                            {model.statRAT}
                        </div>
                    </div>
                )}
                {!!model.statDEF && (
                    <div className="card-stat" title="Defense">
                        <div className="card-stat-name">
                            DEF
                        </div>
                        <div className="card-stat-value">
                            {model.statDEF}
                        </div>
                    </div>
                )}
                {!!model.statARM && (
                    <div className="card-stat" title="Armor">
                        <div className="card-stat-name">
                            ARM
                        </div>
                        <div className="card-stat-value">
                            {model.statARM}
                        </div>
                    </div>
                )}
                {model.statARC && (
                    <div className="card-stat" title="Arcana">
                        <div className="card-stat-name">
                            ARC
                        </div>
                        <div className="card-stat-value">
                            {model.statARC}
                        </div>
                    </div>
                )}
                {!!model.statFURY && (
                    <div className="card-stat" title="Fury">
                        <div className="card-stat-name">
                            FURY
                        </div>
                        <div className="card-stat-value">
                            {model.statFURY}
                        </div>
                    </div>
                )}
                {!!model.statCTRL && (
                    <div className="card-stat" title="Control Range">
                        <div className="card-stat-name">
                            CTRL
                        </div>
                        <div className="card-stat-value">
                            {model.statCTRL}
                        </div>
                    </div>
                )}
                {!!model.statTHR && (
                    <div className="card-stat" title="Threshold">
                        <div className="card-stat-name">
                            THR
                        </div>
                        <div className="card-stat-value">
                            {model.statTHR}
                        </div>
                    </div>
                )}
                {!!model.statESS && (
                    <div className="card-stat" title="Essence">
                        <div className="card-stat-name">
                            ESS
                        </div>
                        <div className="card-stat-value">
                            {model.statESS}
                        </div>
                    </div>
                )}
            </div>
    );
};

export default CardStats;
