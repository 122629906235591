import {getRoot, types} from 'mobx-state-tree';
import CardModel from './cardModel';

const CardsStore = types.model('CardsStore', {
    data: types.array(CardModel),
    selectedID: types.maybeNull(types.string)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    },
    selectCard(id) {
        self.selectedID = id;
    }
})).views(self => ({
    getOneByName(nameText) {
        return self.data.find((item) => {
            return item.fullName.toLowerCase().includes(nameText.toLowerCase())
        });
    },
    get cardsInCurrentFaction() {
        let retArr = self.data.filter(item => ((item.factionId === getRoot(self).factions.selectedID) && (item.editionId === getRoot(self).editions.selectedID)));
        return retArr;
    },
    get groupedCardsInCurrentFaction() {
        let retObj = {};
        self.cardsInCurrentFaction.map((card) => {
            if (!retObj[card.cardType.fullPluralName]) {
                retObj[card.cardType.fullPluralName]=[];
            }
            retObj[card.cardType.fullPluralName].push(card)
            return true;
        })
        return retObj;
    },
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    },
    get selected() {
        return self.data.find(item => item.guidStr === self.selectedID);
    }
}));

export default CardsStore;
