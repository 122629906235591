import React, {useEffect} from 'react';

import HealthGrid from './health/health-grid';
import HealthBlocks from './health/health-blocks';
import MultyHealthBlocks from './health/multy-health-blocks';
import SpiralHealthBlocks from './health/spiral-health-blocks';
import {inject, observer} from "mobx-react";


// Grid or Grid Large
const CardHealth = ({store, isHuge}) => {

    useEffect(() => {

        if (!isHuge) {
            window._smallerValueOfCirclesGrid = 0;
            window._cardRef = store.cards.selected;

            document.getElementById('card-health-holder')?.firstChild?.removeAttribute('style');

            setTimeout(() => {
                const cardAbilitiesBlock = document.getElementById('card-abilities-holder');
                const cardWeaponsBlock = document.getElementById('card-weapons-holder');
                const cardHealthBlock = document.getElementById('card-health-holder')?.firstChild;

                if (cardAbilitiesBlock && cardWeaponsBlock && cardHealthBlock && (window._cardRef.healthType === "Spiral")) {

                    //const cardHealthBlock = .card-health-spiral-box-holder
                    const cardAbilitiesBlockTop = parseInt(window.getComputedStyle(cardAbilitiesBlock, null).getPropertyValue('top').replace('px', ''));
                    const cardAbilitiesBlockLeft = parseInt(window.getComputedStyle(cardAbilitiesBlock, null).getPropertyValue('left').replace('px', ''));
                    const cardWeaponsBlockTop = parseInt(window.getComputedStyle(cardWeaponsBlock, null).getPropertyValue('top').replace('px', ''));
                    const cardWeaponsBlockLeft = parseInt(window.getComputedStyle(cardWeaponsBlock, null).getPropertyValue('left').replace('px', ''));
                    const fullCardHeight = store.application.isMobile ? 390 : 2330; //120px is for bottom border;
                    const fullCardWidth = store.application.isMobile ? 250: 1510;
                    //space under 'abilities' block;
                    const availableHeight1 = fullCardHeight - cardAbilitiesBlockTop - cardAbilitiesBlock.clientHeight;
                    const availableWidth1 = cardAbilitiesBlock.clientWidth;
                    //space under 'abilities' block;
                    const availableHeight2 = fullCardHeight - cardWeaponsBlockTop - cardWeaponsBlock.clientHeight;
                    const availableWidth2 = fullCardWidth - cardWeaponsBlockLeft + (store.application.isMobile ? 20 : 120);
                    const healthBlockHeight = cardHealthBlock.clientHeight;
                    const healthBlockWidth = cardHealthBlock.clientWidth;
                    const minHeight = Math.min(availableHeight1, availableHeight2);
                    const maxHeight = Math.max(availableHeight1, availableHeight2);
                    let zoomByHeight = minHeight / healthBlockHeight;
                    let zoom = 1;
                    //check if healthbar can be increased
                    if (zoomByHeight > 1) {
                        zoom = zoomByHeight;
                        if (zoom > 1.4) {
                            zoom = 1.4;
                        }
                        if (!["Box Multi", "Box", "Grid"].includes(window._cardRef.healthType)) {
                            cardHealthBlock.style.width = `${fullCardWidth / zoom}px`;
                            cardHealthBlock.style.height = `${minHeight / zoom}px`;
                            cardHealthBlock.style.transformOrigin = 'top left';
                            cardHealthBlock.style.position = `absolute`;
                            cardHealthBlock.style.top = `${fullCardHeight - minHeight}px`;
                            cardHealthBlock.style.left = `${cardAbilitiesBlockLeft}px`;
                            cardHealthBlock.style.transform = `scale(${zoom})`;
                        }
                    } else {
                        //check if healthbar can be on one half of card
                        zoomByHeight = maxHeight / healthBlockHeight;
                        if (zoomByHeight > 1) {
                            if (availableHeight1 > availableHeight2) {
                                //left size
                                zoom = zoomByHeight;
                                if ((healthBlockWidth * zoomByHeight) > availableWidth1) {
                                    zoom = availableWidth1 / healthBlockWidth;
                                }
                                if (!["Box Multi", "Box", "Grid"].includes(window._cardRef.healthType)) {
                                    cardHealthBlock.style.width = `${availableWidth1 / zoom}px`;
                                    cardHealthBlock.style.height = `${maxHeight / zoom}px`;
                                    cardHealthBlock.style.transformOrigin = 'top left';
                                    cardHealthBlock.style.position = `absolute`;
                                    cardHealthBlock.style.top = `${cardAbilitiesBlockTop + cardAbilitiesBlock.clientHeight}px`;
                                    cardHealthBlock.style.left = `${cardAbilitiesBlockLeft}px`;
                                    cardHealthBlock.style.transform = `scale(${zoom})`;
                                }
                            } else {
                                //right size
                                zoom = zoomByHeight;
                                if ((healthBlockWidth * zoomByHeight) > availableWidth2) {
                                    zoom = availableWidth2 / healthBlockWidth;
                                }
                                if (!["Box Multi", "Box", "Grid"].includes(window._cardRef.healthType)) {
                                    cardHealthBlock.style.width = `${availableWidth2 / zoom}px`;
                                    cardHealthBlock.style.height = `${maxHeight / zoom}px`;
                                    cardHealthBlock.style.transformOrigin = 'top left';
                                    cardHealthBlock.style.position = `absolute`;
                                    cardHealthBlock.style.top = `${cardWeaponsBlockTop + cardWeaponsBlock.clientHeight}px`;
                                    cardHealthBlock.style.left = `${cardWeaponsBlockLeft}px`;
                                    cardHealthBlock.style.transform = `scale(${zoom})`;
                                }
                            }
                        } else {
                            cardHealthBlock.innerHTML = "";
                            window._cardRef.setNeedSeparateHealth(true);
                        }
                    }
                }
            }, 1)
        }

        // eslint-disable-next-line
    }, [store.cards.selected.guidStr, isHuge]);


    if (isHuge) {
        return (
            <div className="card-huge-health-holder">
                <div className="card-huge-health-inner">
                    <div className="card-health-simple" id="card-health-simple">
                        HEALTH: {store.cards.selected.maxHealth}
                    </div>
                    {(store.cards.selected.healthType === "Box") && (
                        <div className="card-health-box-holder">
                            <div className="card-health-box-container">
                                <HealthBlocks number={store.cards.selected.health(1)}/>
                            </div>
                        </div>
                    )}
                    {(store.cards.selected.healthType === "Box Multi") && (
                        <div className="card-health-multi-box-holder">
                            <MultyHealthBlocks card={store.cards.selected}/>
                        </div>
                    )}
                    {((store.cards.selected.healthType === "Grid") || (store.cards.selected.healthType === "Grid Large")) && (
                        <div className="card-health-grid-holder">
                            <HealthGrid card={store.cards.selected}/>
                        </div>
                    )}
                    {(store.cards.selected.healthType === "Spiral" || (store.cards.selected.healthType === "Spiral Large")) && (
                        <div className="card-health-spiral-box-holder" id="card-health-spiral-box-holder">
                            <SpiralHealthBlocks isHuge={isHuge}/>
                        </div>
                    )}
                </div>
            </div>
        )
    } else if (!store.cards.selected.needSeparateHealthPage) {
        return (
            <div className="card-health-holder" id="card-health-holder">
                {/*Warjack Grid system*/}
                {(store.cards.selected.healthType === "Grid") && (
                    <div className="card-health-grid-holder">
                        <HealthGrid card={store.cards.selected}/>
                    </div>
                )}
                {/*Health bar*/}
                {(store.cards.selected.healthType === "Box") && (
                    <div className="card-health-box-holder">
                        <div
                            className={`card-health-box-container ${(store.cards.selected.health(1) <= 20) ? "card-health-box-container__single-line" : "card-health-box-container__multi-line"}`}>
                            <HealthBlocks number={store.cards.selected.health(1)}/>
                        </div>
                    </div>
                )}
                {(store.cards.selected.healthType === "Box Multi") && (
                    <div className="card-health-multi-box-holder">
                        <MultyHealthBlocks card={store.cards.selected}/>
                    </div>
                )}
                {(store.cards.selected.healthType === "Spiral") && (
                    <div className="card-health-spiral-box-holder" id="card-health-spiral-box-holder">
                        <SpiralHealthBlocks />
                    </div>
                )}
                {((store.cards.selected.healthType === "Spiral Large") || (store.cards.selected.healthType === "Grid Large")) && (
                <div className="card-health-simple" id="card-health-simple" data-qwe={store.cards.selected.healthType}>
                    HEALTH: {store.cards.selected.maxHealth}
                </div>
                    )}
            </div>
        )
    } else return null;
};

export default inject('store')(observer(CardHealth));
