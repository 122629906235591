import { types } from 'mobx-state-tree';
import AbilityModel from "./abilityModel";

const AbilitiesStore = types.model('AbilitiesStore', {
    data: types.array(AbilityModel)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    }
}));

export default AbilitiesStore;
