import { types } from 'mobx-state-tree';
import MiniatureModel from './miniatureModel';

const MiniaturesStore = types.model('MiniaturesStore', {
    data: types.array(MiniatureModel),
    selectedID: types.maybeNull(types.string)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    },
    selectModel(id) {
        self.selectedID = id;
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    },
    getOneByName(nameText) {
        return self.data.find(item => item.fullName.includes(nameText));
    },
    get selected() {
        return self.data.find(item => item.guidStr === self.selectedID);
    }
}));

export default MiniaturesStore;
