import React, {useRef} from 'react';
import {inject, observer} from "mobx-react";

import CardPictureReferences from "./card-picture-referencies";

const CardWeaponAbilities = ({store, card, model, weaponsRef= null, withInfo = true, withWeapons = true, withAbilities = true, withHelp = true,  titleTxt="Weapons"}) => {

    const unusedRef = useRef();

    return (
        <div className={`${withInfo ? "card-info-part" : ""}`}>
            {(model.weapons.length > 0) ?
                <div className="card-info-part-card-name">
                    {card.fullName}: {titleTxt}
                </div> :
                null
            }
            <div className="card-info-part-weapons-and-icons"
                 ref={weaponsRef ? weaponsRef : unusedRef}
                 style={{fontSize: `${store.application.cardWeaponsTextSize}px`}}
            >
                <div className="card-info-part-weapons">
                    {withWeapons && model.weapons.map((weapon, index) => (
                        <div className="card-info-part-weapon" key={index}>
                            <table className="card-info-part-weapon-stats-table">
                                <tbody>
                                <tr>
                                    <td className="card-info-part-weapon-name">
                                        {weapon.fullName}
                                        {(weapon.weaponCount > 1) && (<span>x{weapon.weaponCount}</span>)}
                                    </td>
                                    {weapon.locations &&
                                        <td className="card-info-part-weapon-stat-name">LOCATION</td>}
                                    <td className="card-info-part-weapon-stat-name">TYPE</td>
                                    <td className="card-info-part-weapon-stat-name">RAT</td>
                                    <td className="card-info-part-weapon-stat-name">RNG</td>
                                    <td className="card-info-part-weapon-stat-name">ROF</td>
                                    <td className="card-info-part-weapon-stat-name">AOE</td>
                                    <td className="card-info-part-weapon-stat-name">POW</td>
                                </tr>
                                <tr>
                                    <td className="card-info-part-weapon-name">
                                        {weapon.qualities.map((quality, index) => (
                                            <div className="card-info-part-weapon-quality" key={index}>
                                                <img className="card-weapon-quality-img"
                                                     src={quality.iconPath}
                                                     alt={quality.fullName}
                                                     title={quality.fullName}
                                                />
                                            </div>
                                        ))}
                                    </td>
                                    {weapon.locations && <td>{weapon.weaponLocation}</td>}
                                    <td>{weapon.weaponType}</td>
                                    <td>{model.statRAT}</td>
                                    <td>{weapon.statRNG}</td>
                                    <td>{weapon.statROF}</td>
                                    <td>{weapon.statAOE}</td>
                                    <td>{weapon.statPOW}</td>
                                </tr>
                                </tbody>
                            </table>

                            {weapon.abilities.map((ability, index) => (
                                <div className="card-info-part-weapon-ability" key={index}>
                                    {ability.fullName}
                                </div>
                            ))}
                        </div>
                    ))}
                    {withAbilities && <div className="card-info-part-abilities">
                        {model.uniqueWeaponAbilities.map((ability, index) => (
                            <div
                                className={`card-info-part-ability ${ability.fullName.includes("•") ? "card-info-part-sub_ability" : ""}`}
                                key={index}>
                                <div className="card-info-part-ability-name">
                                    {ability.fullName}
                                </div>
                                <div className="card-info-part-ability-description">
                                    {ability.description[0]}
                                </div>
                            </div>
                        ))}
                    </div>
                    }
                </div>
                {withHelp &&
                    <>
                        <div className="card-info-part-card-name">
                            {card.fullName}: Icons explanation
                        </div>
                        <CardPictureReferences card={card} model={model}/>
                    </>
                }
            </div>
        </div>
    );
};

export default inject('store')(observer(CardWeaponAbilities));
