import { types } from 'mobx-state-tree';
import SpellModel from "./spellModel";

const SpellsStore = types.model('SpellsStore', {
    data: types.array(SpellModel)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    }
}));

export default SpellsStore;
