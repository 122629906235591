import React from 'react';
import CardOptionGroup from "./card-option-group";
import CardSimpleOptionGroup from "./card-simple-option-group";
import {inject, observer} from "mobx-react";

const CardOptions = ({store, card, isSimple = false}) => {

    if (isSimple) {
        return (
            <div className="card-simple-options-holder">
                <div className="card-simple-options-group-title">Options:</div>
                {!!card.cardOption1Ids.length &&
                    <CardSimpleOptionGroup card={card} groupNumber={1} name={card.cardOption1Name[0]}/>
                }
                {!!card.cardOption2Ids.length &&
                    <CardSimpleOptionGroup card={card} groupNumber={2} name={card.cardOption2Name[0]}/>
                }
                {!!card.cardOption3Ids.length &&
                    <CardSimpleOptionGroup card={card} groupNumber={3} name={card.cardOption3Name[0]}/>
                }
                {!!card.cardOption4Ids.length &&
                    <CardSimpleOptionGroup card={card} groupNumber={4} name={card.cardOption4Name[0]}/>
                }
                {!!card.cardOption5Ids.length &&
                    <CardSimpleOptionGroup card={card} groupNumber={5} name={card.cardOption5Name[0]}/>
                }
                {!!card.cardOption6Ids.length &&
                    <CardSimpleOptionGroup card={card} groupNumber={6} name={card.cardOption6Name[0]}/>
                }
            </div>
        )
    } else return (
        <div className="card-info-part">
            <div className="card-info-part-card-name">
                {store.cards.selected.fullName}: Options
            </div>
            <div className="card-options-holder">
                {!!card.cardOption1Ids.length &&
                    <CardOptionGroup card={card} groupNumber={1} name={card.cardOption1Name[0]}/>
                }
                {!!card.cardOption2Ids.length &&
                    <CardOptionGroup card={card} groupNumber={2} name={card.cardOption2Name[0]}/>
                }
                {!!card.cardOption3Ids.length &&
                    <CardOptionGroup card={card} groupNumber={3} name={card.cardOption3Name[0]}/>
                }
                {!!card.cardOption4Ids.length &&
                    <CardOptionGroup card={card} groupNumber={4} name={card.cardOption4Name[0]}/>
                }
                {!!card.cardOption5Ids.length &&
                    <CardOptionGroup card={card} groupNumber={5} name={card.cardOption5Name[0]}/>
                }
                {!!card.cardOption6Ids.length &&
                    <CardOptionGroup card={card} groupNumber={6} name={card.cardOption6Name[0]}/>
                }
            </div>
        </div>
    );
}

export default inject('store')(observer(CardOptions));