import React from 'react';


const CardSpell = ({spell}) => {
    return (
        <div className="card-info-part-spell">
            <div className="card-info-part-spell-stats">
                <table className="card-info-part-spell-stats-table">
                    <tbody>
                    <tr>
                        <td rowSpan="2" className="card-info-part-spell-name">{spell.name[0]}</td>
                        <td className="card-info-part-spell-stat-name">COST</td>
                        <td className="card-info-part-spell-stat-name">RNG</td>
                        <td className="card-info-part-spell-stat-name">AOE</td>
                        <td className="card-info-part-spell-stat-name">POW</td>
                        <td className="card-info-part-spell-stat-name">DUR</td>
                        <td className="card-info-part-spell-stat-name">OFF</td>
                    </tr>
                    <tr>
                        <td>{spell.statCOST}</td>
                        <td>{spell.statRNG}</td>
                        <td>{spell.statAOE}</td>
                        <td>{spell.statPOW}</td>
                        <td>{spell.statDUR}</td>
                        <td>{spell.statOFF}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="card-info-part-spell-description">
                {spell.description[0]}
            </div>
        </div>
    );
};

export default CardSpell;
