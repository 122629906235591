import React from 'react';

import CardPreview from '../card/card-preview';
import Modal from '@mui/material/Modal';

const CardComponent = ({cardInfoOpen, closePopup, handleSave, loading}) => {

    const onClose = (e, reason) => {
        if (reason !== "backdropClick" ) {closePopup()}
    }

    return (
        <Modal
            open={cardInfoOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgb(66,61,61)',
                    },
                },
            }}
        >
            <CardPreview closePopup={closePopup}
                         handleSave={handleSave}
                         loading={loading}
            />
        </Modal>
    );
};

export default CardComponent;
