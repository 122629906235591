import { types } from 'mobx-state-tree';
import CardTypeModel from './cardTypeModel';

const CardTypesStore = types.model('CardTypesStore', {
    data: types.array(CardTypeModel)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    }
}));

export default CardTypesStore;
