import {getRoot, types} from 'mobx-state-tree';

const ArmyModel = types
    .model('ArmyModel', {
        arenaId: types.string,
        armyCommandCardIds: types.maybeNull(types.array(types.string)),
        commandCardAdj: types.maybeNull(types.number),
        dataBundleId: types.maybeNull(types.string),
        deleted: types.boolean,
        editionId: types.string,
        excludedCardIds: types.maybeNull(types.array(types.string)),
        factionId: types.string,
        guidStr: types.identifier,
        helpTermIds: types.maybeNull(types.array(types.string)),
        iconBnWPath: types.string,
        iconColorPath: types.string,
        includedCardIds: types.maybeNull(types.array(types.string)),
        includedKeywordsIds: types.maybeNull(types.array(types.string)),
        isLive: types.boolean,
        isUnlimited: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string),
    }).views(self => ({
        get fullName() {
            return self.name[0];
        },
        get cards() {
            let retArr = [];
            if (!self.isUnlimited) {
                getRoot(self).cards.data.map((card) => {
                    const found = card.keywordsIds.some(r => self.includedKeywordsIds.includes(r));
                    if (found) {
                        retArr.push(card);
                    }
                    return true;
                });
            } else {
                getRoot(self).cards.data.map((card) => {
                    if (card.factionId === self.factionId) {
                        retArr.push(card);
                    }
                    return true;
                });
            }
            //adding mercs
            let uniqueArr = [];
            self.includedCardIds.map((cardId) => {
                if (!uniqueArr.includes(cardId)) {
                    let found = getRoot(self).cards.getByGuid(cardId);
                    if (found) {
                        retArr.push(found);
                    }
                }
                return true;
            });
            //sorting a-z
            retArr.sort((a, b) => {
                if (a.fullName < b.fullName) return -1;
                if (a.fullName > b.fullName) return 1;
                return 0;
            });
            return retArr;
        },
        get groupedCards() {
            let retObj = {};
            let uniques = {};
            self.cards.map((card) => {
                if (!retObj[card.cardType.fullPluralName]) {
                    uniques[card.cardType.fullPluralName]=[];
                    retObj[card.cardType.fullPluralName]=[];
                }
                if (!uniques[card.cardType.fullPluralName].includes(card.guidStr)) {
                    retObj[card.cardType.fullPluralName].push(card);
                    uniques[card.cardType.fullPluralName].push(card.guidStr)

                }
                return true;
            })
            return retObj;
        },
        // get groupedAllCardsInAllArmies() {
        //     let retObj = {};
        //     getRoot(self).cards.data.map((card) => {
        //         if (!retObj[card.cardType.fullPluralName]) {
        //             retObj[card.cardType.fullPluralName]=[];
        //         }
        //         retObj[card.cardType.fullPluralName].push(card)
        //         return true;
        //     })
        //     return retObj;
        // },
    }));

export default ArmyModel;

