import { types } from 'mobx-state-tree';
import SpellCardModelModel from "./spellCardModel";

const SpellCardsStore = types.model('SpellCardsStore', {
    data: types.array(SpellCardModelModel)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    }
}));

export default SpellCardsStore;
