import React, {useEffect, useRef} from 'react';
import {inject, observer} from "mobx-react";
import {useParams} from 'react-router-dom';

import CardMainBlock from './card-main-block';

let refreshSpeed = 10;
window.abilitiesRedrawInterval = [];
window.spellsRedrawInterval = [];
window.weaponsRedrawInterval = [];
window.weaponsRedrawInterval2 = [];
window.spellsRackRedrawInterval = [];
window.spellsRackRedrawInterval2 = [];


const CardResizersAndMath = ({store, printRef}) => {

    const {cardId} = useParams();

    const abilitiesRef = useRef();
    const spellsRef = useRef();
    const spellOptionsRef1 = useRef();
    const spellOptionsRef2 = useRef();
    const spellOptionsRef3 = useRef();
    const weaponsRef = useRef();
    const cardWeaponsRef = useRef();

    useEffect(() => {
        if (cardId !== store.cards.selectedID) {
            store.cards.selectCard(cardId);
        }
        const headerHeight = !store.isMobile ? 64 : 11;
        const footerHeight = !store.isMobile ? 80 : 13;
        const cardHeight = !store.isMobile ? 2450 : 408;
        let availableHeight = (window.innerHeight - headerHeight - footerHeight);

        if(!store.application.isMobile) {
            store.application.setZoom(availableHeight / cardHeight);
        }

        cleanup();

        window.abilitiesRedrawInterval.push(setInterval(() => {
            fontSizer({
                ref: abilitiesRef,
                attempts: window.attempts1,
                setter: store.application.setCardAbilitiesTextSize,
                value: store.application.cardAbilitiesTextSize,
                interval: window.abilitiesRedrawInterval,
                sizeSetter: store.application.setAbilitiesBlockSize,
            })
        }, refreshSpeed))

        window.spellsRedrawInterval.push(setInterval(() => {
            fontSizer({
                ref: spellsRef,
                attempts: window.attempts2,
                setter: store.application.setCardSpellTextSize,
                value: store.application.cardSpellTextSize,
                interval: window.spellsRedrawInterval,
            })
        }, refreshSpeed))

        window.weaponsRedrawInterval.push(setInterval(() => {
            fontSizer({
                ref: weaponsRef,
                attempts: window.attempts3,
                setter: store.application.setCardWeaponsTextSize,
                value: store.application.cardWeaponsTextSize,
                interval: window.weaponsRedrawInterval,
                sizeSetter: store.application.setWeaponsBlockSize,
                preProcessor: store.application.processInitialWeaponSize
            })
        }, refreshSpeed));

        window.weaponsRedrawInterval2.push(setInterval(() => {
            fontSizer({
                ref: cardWeaponsRef,
                attempts: window.attempts6,
                setter: store.application.setCardWeaponsTextSize2,
                value: store.application.cardWeaponsTextSize2,
                interval: window.weaponsRedrawInterval2,
                maxSize: !store.application.isMobile ? 1500 : 250
            })
        }, refreshSpeed));

        window.spellsRackRedrawInterval.push(setInterval(() => {
            fontSizer({
                ref: spellOptionsRef1,
                attempts: window.attempts4,
                setter: store.application.setCardSpellRackTextSize,
                value: store.application.cardSpellRackTextSize,
                interval: window.spellsRackRedrawInterval,
            })
        }, refreshSpeed));
        window.spellsRackRedrawInterval2.push(setInterval(() => {
            fontSizer({
                ref: spellOptionsRef2,
                attempts: window.attempts5,
                setter: store.application.setCardSpellRackTextSize,
                value: store.application.cardSpellRackTextSize,
                interval: window.spellsRackRedrawInterval2,
            })
        }, refreshSpeed));


        return () => {
            cleanup();
        };
        // eslint-disable-next-line
    }, [cardId, store.cards.selectedID, store.application.cardZoom]);

    const cleanup = () => {
        store.application.setCardSpellTextSize(store.application.defaultTextSize);
        store.application.setCardAbilitiesTextSize(store.application.defaultTextSize);
        store.application.setCardWeaponsTextSize(store.application.defaultTextSize);
        store.application.setCardWeaponsTextSize2(store.application.defaultTextSize);
        store.application.setCardSpellRackTextSize(store.application.defaultTextSize);
        window.attempts1 = 0;
        window.attempts2 = 0;
        window.attempts3 = 0;
        window.attempts4 = 0;
        window.attempts5 = 0;
        window.attempts6 = 0;
        store.application.setAbilitiesBlockSize(store.application.maxInfoPageSize);
        store.application.setWeaponsBlockSize(store.application.maxInfoPageSize);
        for (let i = 1; i < window.abilitiesRedrawInterval.length; i++) {
            clearInterval(window.abilitiesRedrawInterval[i]);
        }
        for (let i = 1; i < window.spellsRedrawInterval.length; i++) {
            clearInterval(window.spellsRedrawInterval[i]);
        }
        for (let i = 1; i < window.weaponsRedrawInterval.length; i++) {
            clearInterval(window.weaponsRedrawInterval[i]);
        }
        for (let i = 1; i < window.weaponsRedrawInterval2.length; i++) {
            clearInterval(window.weaponsRedrawInterval2[i]);
        }
        for (let i = 1; i < window.spellsRackRedrawInterval.length; i++) {
            clearInterval(window.spellsRackRedrawInterval[i]);
        }
        for (let i = 1; i < window.spellsRackRedrawInterval2.length; i++) {
            clearInterval(window.spellsRackRedrawInterval2[i]);
        }
    }

    const fontSizer = ({
                           ref,
                           attempts,
                           setter,
                           value,
                           interval,
                           sizeSetter = null,
                           preProcessor = null,
                           maxSize = store.application.maxInfoPageSize
                       }) => {
        if (ref.current) {
            attempts++;
            if (((ref.current.clientHeight) > maxSize) && (attempts < 40)) {
                setter(value - 1);
                if (preProcessor) {
                    preProcessor(ref.current.clientHeight);
                }
            } else {
                sizeSetter && sizeSetter(ref.current.clientHeight);
                for (let i = 0; i < interval.length; i++) {
                    clearInterval(interval[i]);
                }
            }
        }
    }

    const card = store.cards.selected;
    let model;
    if (store.models.selected) {
        model = store.models.selected;
    } else if (store.cards.selected && store.cards.selected.model) {
        console.log('store.cards.selected',store.cards.selected)
        model = store.cards.selected.model;
    }

    if (model) {
        return (
            <CardMainBlock
                card={card}
                model={model}
                abilitiesRef={abilitiesRef}
                spellsRef={spellsRef}
                spellOptionsRef1={spellOptionsRef1}
                spellOptionsRef2={spellOptionsRef2}
                spellOptionsRef3={spellOptionsRef3}
                weaponsRef={weaponsRef}
                cardWeaponsRef={cardWeaponsRef}
                printRef={printRef}
            />
        )
    } else if (card && ((card.models.length > 1)||(card.grantedModels.length > 1))) {
        return (
            <div id="art-station" ref={printRef} style={{
                transform: `scale(${store.application.cardZoom})`,
                MozTransform: `scale(${store.application.cardZoom})`,
                WebkitTransform: `scale(${store.application.cardZoom})`,
            }}>
                <div className="card-content">
                    <div className="card-background"/>
                    <div className="card-border-2"/>
                    <div className="card-info-part">
                        <div className="card-info-part-card-name">
                            {card.fullName}
                        </div>
                        <div className="card-info-part-icon-references">
                            Please select a model from left menu to generate cards for.
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (card) {
        return (
            <div id="art-station" ref={printRef} style={{
                transform: `scale(${store.application.cardZoom})`,
                MozTransform: `scale(${store.application.cardZoom})`,
                WebkitTransform: `scale(${store.application.cardZoom})`,
            }}>
                <div className="card-content">
                    <div className="card-background"/>
                    <div className="card-border-2"/>
                    <div className="card-info-part">
                        <div className="card-info-part-card-name">
                            {card.fullName}
                        </div>
                        <div className="card-info-part-icon-references">
                            Error: This card has no models in DB.
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }

};

export default inject('store')(observer(CardResizersAndMath));
