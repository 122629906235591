import {types} from 'mobx-state-tree';

const WeaponQualityModel = types
    .model('WeaponQualityModel', {
        deleted: types.boolean,
        displayPriority: types.integer,
        guidStr: types.identifier,
        helpTermIds: types.maybeNull(types.array(types.string)),
        iconPath: types.string,
        isLive: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string)
    }).views(self => ({
            get fullName() {
                    return self.name[0];
            },
    }));

export default WeaponQualityModel;

