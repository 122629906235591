import {getRoot, types} from 'mobx-state-tree';

const FactionModel = types
    .model('FactionModel', {
        deleted: types.boolean,
        guidStr: types.identifier,
        helpTermIds: types.maybeNull(types.array(types.string)),
        hexColor: types.maybeNull(types.string),
        hexColor2: types.maybeNull(types.string),
        iconColorPath: types.string,
        iconBnWPath: types.string,
        isLive: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string),
        shortName: types.string,
    })
    .views(self => ({
        get fullName() {
            return self.name[0];
        },
        get armies() {
            let retArr = getRoot(self).armies.data.filter(item => (item.factionId === self.guidStr));
            retArr.sort((a, b) => a.fullName.localeCompare(b.fullName));
            return retArr;
        },
        get spells() {
            let arr1 = getRoot(self).spellCards.data.filter(item => (item.factionId === self.guidStr));
            arr1.sort((a, b) => a.name[0].localeCompare(b.name[0]));
            let retArr = [];
            arr1.forEach((element) => {
                retArr.push(getRoot(self).spells.getByGuid(element.spellId));
            });
            return retArr;
        }
    }));

export default FactionModel;