import React from 'react';

const CardAbilities = ({card, model}) => {

    return (
        <>
            <div className="card-model-short-name">
                {model.fullName.toLowerCase()}
            </div>
            <div className="card-abilities-holder" id="card-abilities-holder">
                {model.abilities.map((ability, index) => (
                    <div className="card-ability" key={index}>
                        {ability.fullName}
                    </div>
                ))
                }
                {!!card.feat.length &&
                <div className="card-abilities-feat">
                    <div className="card-abilities-feat-title">
                       Feat
                    </div>
                    <div className="card-abilities-feat-name">
                        {card.feat}
                    </div>
                </div>
                }
                {card.animus && (
                    <div className="card-abilities-animus">
                        <div className="card-abilities-animus-title">
                            Animus
                        </div>
                        <div className="card-abilities-animus-name">
                            {card.animus.name[0]}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default CardAbilities;
