import {types} from 'mobx-state-tree';

const CardTypeModel = types
    .model('CardTypeModel', {
            guidStr: types.identifier,
            attachedLimit: types.integer,
            battlegroupController: types.boolean,
            battlegroupMember: types.boolean,
            battlegroupTypeIds: types.array(types.string),
            deleted: types.boolean,
            helpTermIds: types.array(types.string),
            isLive: types.boolean,
            listSortOrder: types.integer,
            mustBeAttached: types.boolean,
            name: types.array(types.string),
            pluralName: types.array(types.string),
            spellsLabelOverride: types.array(types.string),
    }).views(self => ({
        get fullName() {
            return self.name[0];
        },
        get fullPluralName() {
            return self.pluralName[0];
        },
    }));

export default CardTypeModel;