import React from 'react';
import {inject, observer} from "mobx-react";

const PicRefList = ({card, model}) => {
    let ret = [];
    let uinques = [];

    model.advantages.map((advantage, index) => {
        if (!uinques.includes(advantage.guidStr)) {
            uinques.push(advantage.guidStr);
            ret.push(<div className="card-info-part-icon-reference" key={index}>
                <div className="card-info-part-weapon-quality">
                    <img className="card-weapon-quality-img"
                         src={advantage.iconPath}
                         alt={advantage.fullName}
                         title={advantage.fullName}
                    />
                </div>
                <div className="card-info-part-icon-reference-name"> - {advantage.fullName}</div>
            </div>);
        }
        return true;
    });

    model.uniqueWeaponQualities.map((quality) => {
        if (!uinques.includes(quality.guidStr)) {
            uinques.push(quality.guidStr);
            ret.push(<div className="card-info-part-icon-reference" key={Math.random()}>
                <div className="card-info-part-weapon-quality">
                    <img className="card-weapon-quality-img"
                         src={quality.iconPath}
                         alt={quality.fullName}
                         title={quality.fullName}
                    />
                </div>
                <div className="card-info-part-icon-reference-name"> - {quality.fullName}</div>
            </div>);
        }
        return true;
    });

    card.allOptions.map((option) => {

        if (option.grantedAbilities.length > 0) {
            option.grantedAdvantages.map((advantage) => {
                if (!uinques.includes(advantage.guidStr)) {
                    uinques.push(advantage.guidStr);
                    ret.push(<div className="card-info-part-icon-reference" key={Math.random()}>
                        <div className="card-info-part-weapon-quality">
                            <img className="card-weapon-quality-img"
                                 src={advantage.iconPath}
                                 alt={advantage.fullName}
                                 title={advantage.fullName}
                            />
                        </div>
                        <div className="card-info-part-icon-reference-name"> - {advantage.fullName}</div>
                    </div>);
                }
                return true;
            });
        }

        if (option.weapon && (option.weapon.qualities.length > 0)) {
            option.weapon.qualities.map((quality) => {
                if (!uinques.includes(quality.guidStr)) {
                    uinques.push(quality.guidStr);
                    ret.push(<div className="card-info-part-icon-reference" key={Math.random()}>
                        <div className="card-info-part-weapon-quality">
                            <img className="card-weapon-quality-img"
                                 src={quality.iconPath}
                                 alt={quality.fullName}
                                 title={quality.fullName}
                            />
                        </div>
                        <div className="card-info-part-icon-reference-name"> - {quality.fullName}</div>
                    </div>);
                }
                return true;
            });
        }
        return true;
    });

    return (
        <div className="card-info-part-abilities">
            {ret}
        </div>
    )
}

const CardPictureReferences = ({card, model}) => {

    return (
        <div className="card-info-part-icon-references">
            <div className="card-info-part-icon-references-title">Icons explanation</div>
            <PicRefList card={card} model={model}/>
        </div>

    );
};

export default inject('store')(observer(CardPictureReferences));
