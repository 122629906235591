import {types, getRoot} from 'mobx-state-tree';

const OptionModel = types
    .model('OptionModel', {
            cardAbilitySorting: types.maybeNull(types.array(types.string)),
            changeMainPortrait: types.boolean,
            deleted: types.boolean,
            grantdWeaponQualityIds:  types.maybeNull(types.array(types.string)),
            grantedCardAbilityIds:  types.maybeNull(types.array(types.string)),
            grantedModelAbilityIds:  types.maybeNull(types.array(types.string)),
            grantedModelAdvantageIds:  types.maybeNull(types.array(types.string)),
            grantedModelIds:  types.maybeNull(types.array(types.string)),
            grantedSpellIds:  types.maybeNull(types.array(types.string)),
            grantedStatAdj:  types.maybeNull(types.array(types.string)),
            grantedWeaponAbilityIds: types.maybeNull(types.array(types.string)),
            grantedWeaponIds:  types.maybeNull(types.array(types.string)),
            guidStr: types.identifier,
            isLive: types.boolean,
            metadata: types.maybeNull(types.string),
            modelAbilitySorting: types.maybeNull(types.array(types.string)),
            modelSorting: types.maybeNull(types.array(types.string)),
            name: types.maybeNull(types.array(types.string)),
            pointCost: types.maybeNull(types.string),
            portraitPath: types.maybeNull(types.string),
            spellSorting: types.maybeNull(types.array(types.string)),
            weaponAbilitySorting: types.maybeNull(types.array(types.string)),
            weaponSorting: types.maybeNull(types.array(types.string)),

    }).views(self => ({
            get fullName() {
                return self.name[0];
            },
            get grantedAbilities() {
                let retArr = [];
                self.grantedModelAbilityIds?.forEach(abilityId => {
                    retArr.push(getRoot(self).modelAbilities.getByGuid(abilityId));
                });
                return retArr;
            },
            get grantedAdvantages() {
                let retArr = [];
                self.grantedModelAdvantageIds?.forEach(advID => {
                    retArr.push(getRoot(self).modelAdvantages.getByGuid(advID));
                });
                return retArr;
            },
            get weapon() {
                if (self.weaponSorting[0]) {
                    let id = self.weaponSorting[0].split(':')[1];
                    let weapon = getRoot(self).weapons.getByGuid(id);
                    return weapon;
                } else {
                    return null;
                }
            },
            get spells() {
                let retArr = [];
                self.grantedSpellIds?.forEach(spellID => {
                    retArr.push(getRoot(self).spells.getByGuid(spellID));
                });
                return retArr;
            }
    }));

export default OptionModel;

