import React from 'react';
import {inject, observer} from "mobx-react";
import {useNavigate} from 'react-router-dom';

import logoImg from "../../static/images/logo.png";

const MainMenu = ({store}) => {

    const navigate = useNavigate();

    if (store.editions.data.length) {
        return (
            <div className="main-menu-part-1">
                <img className="main-menu-logo" src={logoImg} alt="Logo"/>
                <div className="main-menu-items-holder">
                    <div className="main-menu-item__disabled">
                        <div className="main-menu-item-icon">⚔</div>
                        <div>Play</div>
                    </div>
                    <div className="main-menu-item__disabled">
                        <div className="main-menu-item-icon">✎</div>
                        <div>Build a force</div>
                    </div>
                    <div className="main-menu-item"
                         onClick={() => navigate('/cards')}
                    >
                        <div className="main-menu-item-icon">🃌</div>
                        <div>Cards</div>
                    </div>
                    <div className="main-menu-item__disabled">
                        <div className="main-menu-item-icon">🕮</div>
                        <div>Library</div>
                    </div>
                </div>
                <div className="about-me">
                    <p>App is coded by some <a href={"https://t.me/geekerboloid"}>@Geekerboloid</a></p>
                    <p>Feel free to contact me. In case I am braking any policies, laws or etc. with this app - tell me, and I will remove it.</p>
                </div>
            </div>
        );
    }
};

export default inject('store')(observer(MainMenu));
