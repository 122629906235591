import React from 'react';
import CardSpell from './card-spell';
import {inject, observer} from "mobx-react";


const CardSpellSubPart = ({spells}) => {
    let ret = [];
    let oddsRet = [];
    let evenRet = [];
    for (let i = 0; i < spells.length; i += 2) {
        oddsRet.push(
            <div className="card-info-part-card-rack-spell" key={Math.random()}>
                <div className="card-info-part-card-rack-spell-picker"/>
                <div className="card-info-part-spell-name">{spells[i].name[0]}</div>
            </div>
        )
    }
    for (let j = 1; j < spells.length; j += 2) {
        evenRet.push(
            <div className="card-info-part-card-rack-spell" key={Math.random()}>
                <div className="card-info-part-card-rack-spell-picker"/>
                <div className="card-info-part-spell-name">{spells[j].name[0]}</div>
            </div>
        )
    }
    ret.push(
        <div className="card-info-part-card-rack-list-subpart" key={Math.random()}>
            {oddsRet}
        </div>
    )
    ret.push(
        <div className="card-info-part-card-rack-list-subpart" key={Math.random()}>
            {evenRet}
        </div>
    )
    return ret;
}

const CardSpellPart = ({store, spellsRef}) => {

    return (
        <div className="card-info-part">
            <div className="card-info-part-card-name">
                {store.cards.selected.fullName}: Spells
            </div>
            <div id="card-spells-part"
                 className="card-info-part-spells"
                 ref={spellsRef}
                 style={{fontSize: `${store.application.cardSpellTextSize}px`}}
            >
                {store.cards.selected.spells.map((spell, index) => (
                    <CardSpell spell={spell} key={index}/>
                ))
                }
                {(store.cards.selected.spellRackOptions.length > 0) &&
                    <>
                        <div className="card-info-part-card-text">
                            Can also select any <b>{store.cards.selected.spellRackOptions}</b> from spell rack:
                        </div>
                        <div className="card-info-part-card-rack-list">
                            <div className="card-info-part-card-rack-list-part">
                                <CardSpellSubPart spells={store.cards.selected.faction.spells} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default inject('store')(observer(CardSpellPart));
