import {getRoot, types} from 'mobx-state-tree';
import ArmyModel from './armyModel';

const ArmiesStore = types.model('ArmiesStore', {
    data: types.array(ArmyModel),
    selectedID: types.maybeNull(types.string)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    },
    selectArmy(id) {
        self.selectedID = id;
        getRoot(self).cards.selectCard(null);
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    },
    armiesInFaction(id) {
        let retArr = self.data.filter(item => ((item.factionId === id) && (item.editionId === getRoot(self)?.editions?.selectedID)));
        retArr.sort((a, b) => a.fullName.localeCompare(b.fullName));
        return retArr;
    },
    get selected() {
        return self.data.find(item => item.guidStr === self.selectedID);
    }
}));

export default ArmiesStore;
