import {getRoot, types} from 'mobx-state-tree';
import FactionModel from './factionModel';

const FactionsStore = types.model('FactionsStore', {
    data: types.array(FactionModel),
    selectedID: types.maybeNull(types.string)
}).actions(self => ({
    add(item) {
        self.data.push({ ...item });
    },
    selectFaction(id) {
        self.selectedID = id;
        getRoot(self).armies.selectArmy(null);
        getRoot(self).cards.selectCard(null);
    }
})).views(self => ({
    getByGuid(id) {
        return self.data.find(item => item.guidStr === id);
    },
    get selected() {
        return self.data.find(item => item.guidStr === self.selectedID);
    }
}));

export default FactionsStore;
