import React from 'react';
import CardSpell from './card-spell';
import {inject, observer} from "mobx-react";
import CardWeaponAbilities from "./card-weapons-part";


const AbilitiesSubcomponent = ({card,  model}) => {
    let ret = [];
    let uinques = [];

    model.abilities.map((ability, index) => {
        if (!uinques.includes(ability.guidStr)) {
            ret.push(<div
                className={`card-info-part-ability ${ability.fullName.includes("•") ? "card-info-part-sub_ability" : ""}`}
                key={index}>
                <div className="card-info-part-ability-name">
                    {ability.fullName}
                </div>
                <div className="card-info-part-ability-description">
                    {ability.description[0]}
                </div>
            </div>);
            uinques.push(ability.guidStr)
        }
        return true;
    })

    card.allOptions.map((option) => {
        if (option.grantedAbilities.length > 0) {
            option.grantedAbilities.map((ability) => {
                if (!uinques.includes(ability.guidStr)) {
                    ret.push(<div
                        className={`card-info-part-ability ${ability.fullName.includes("•") ? "card-info-part-sub_ability" : ""}`}
                        key={Math.random()}>
                        <div className="card-info-part-ability-name">
                            ✶ {ability.fullName} ({option.fullName})
                        </div>
                        <div className="card-info-part-ability-description">
                            {ability.description[0]}
                        </div>
                    </div>)
                    uinques.push(ability.guidStr);
                }
                return true;
            });
        }

        if (option.weapon && (option.weapon.abilities.length > 0)) {
            option.weapon.abilities.map((ability) => {
                if (!uinques.includes(ability.guidStr)) {
                    ret.push(<div
                        className={`card-info-part-ability ${ability.fullName.includes("•") ? "card-info-part-sub_ability" : ""}`}
                        key={Math.random()}>
                        <div className="card-info-part-ability-name">
                            ✶ {ability.fullName} ({option.fullName})
                        </div>
                        <div className="card-info-part-ability-description">
                            {ability.description[0]}
                        </div>
                    </div>)
                    uinques.push(ability.guidStr);
                }
            return true;
            })
        }
        return true;
    });

    return (
        <div className="card-info-part-abilities">
            {ret}
        </div>
    )
}

const CardInfoPart = ({store, abilitiesRef, weaponsRef, card, model}) => {

    return (
        <div className="card-info-part">
            {(model.abilities.length > 0) &&
                <div className="card-info-part-card-name">
                    {card.fullName}{store.models.selected && ` - ${store.models.selected.fullName}`}: Abilities
                </div>
            }
            <div className="card-info-part-abilities-holder"
                 ref={abilitiesRef}
                 style={{fontSize: `${store.application.cardAbilitiesTextSize}px`}}
            >
                <AbilitiesSubcomponent card={card} model={model}/>
                {card.feat && (
                    <div className="card-info-part-feat">
                        <div className="card-info-part-feat-name">
                            FEAT: {card.feat}
                        </div>
                        <div className="card-info-part-feat-description">
                            {card.featDescription[0]}
                        </div>
                    </div>
                )}
                {card.animus && (
                    <div className="card-info-part-animus">
                        <div className="card-info-part-animus-name">
                            ANIMUS
                        </div>
                        <CardSpell spell={card.animus}/>
                    </div>
                )}
                {(((store.application.abilitiesBlockSize + store.application.weaponsBlockSize) < (store.application.maxInfoPageSize)) &&
                        (model.weapons)) &&
                    <CardWeaponAbilities
                        weaponsRef={weaponsRef}
                        withInfo={false}
                        card={card}
                        withWeapons={true}
                        withHelp={!card.needSeparateHelperPage}
                        withAbilities={!card.needNewPageForWeaponAbilities}
                        model={model}
                    />
                }
            </div>
        </div>
    );
};

export default inject('store')(observer(CardInfoPart));
