import { applySnapshot, types } from 'mobx-state-tree';
import appData from '../data/data general.json';
import * as MobileDetect from 'mobile-detect';

import ApplicationStore from "./models/application";
import ArmiesStore from "./models/army";
import FactionsStore from "./models/faction";
import MiniaturesStore from "./models/model";
import CardsStore from "./models/cards";
import CardTypesStore from "./models/card-type";
import KeywordsStore from "./models/keyword";
import WeaponsStore from "./models/weapon";
import AdvantageStore from "./models/advantage";
import WeaponQualitiesStore from "./models/weapon-quality";
import OptionsStore from "./models/option";
import AbilitiesStore from "./models/ability";
import EditionsStore from "./models/edition";
import SpellsStore from "./models/spells";
import SpellCardsStore from "./models/spellCards";

const RootStore = types
    .model('RootStore', {
        application: ApplicationStore,
        armies: ArmiesStore,
        factions: FactionsStore,
        models: MiniaturesStore,
        cards: CardsStore,
        cardTypes: CardTypesStore,
        keywords: KeywordsStore,
        weapons: WeaponsStore,
        modelAdvantages: AdvantageStore,
        weaponQualities:WeaponQualitiesStore,
        cardOptions:OptionsStore,
        cardAbilities:AbilitiesStore,
        modelAbilities:AbilitiesStore,
        weaponAbilities:AbilitiesStore,
        editions: EditionsStore,
        spells: SpellsStore,
        spellCards: SpellCardsStore,
    });

let store = null;
let md = new MobileDetect(window.navigator.userAgent);

const initStore = (snapshot = null) => {
    if (store === null) {

        console.log(appData, 'appdata');
        let isMob = !!md.mobile();

        store = RootStore.create({
            application: ApplicationStore.create({
                cardSpellTextSize: isMob ? 7 : 46,
                cardSpellRackTextSize: isMob ? 7 : 46,
                cardAbilitiesTextSize: isMob ? 7 : 46,
                cardWeaponsTextSize: isMob ? 7 : 46,
                cardWeaponsTextSize2: isMob ? 7 : 46,
                defaultTextSize: isMob ? 7 : 46,
                weaponsBlockSize: isMob ? 342 : 2050,
                abilitiesBlockSize: isMob ? 342 : 2050,
                maxInfoPageSize: isMob ? 342 : 2050,
                cardZoom: 1,
                isMobile: isMob,
            }),
            editions: EditionsStore.create({data: []}),
            armies: ArmiesStore.create({data: []}),
            factions: FactionsStore.create({data: []}),
            models: MiniaturesStore.create({data: []}),
            cards: CardsStore.create({data: []}),
            cardTypes: CardTypesStore.create({data: []}),
            keywords: KeywordsStore.create({data: []}),
            weapons: WeaponsStore.create({data: []}),
            modelAdvantages: AdvantageStore.create({data: []}),
            weaponQualities: WeaponQualitiesStore.create({data: []}),
            cardOptions: OptionsStore.create({data: []}),
            cardAbilities: AbilitiesStore.create({data: []}),
            modelAbilities: AbilitiesStore.create({data: []}),
            weaponAbilities: AbilitiesStore.create({data: []}),
            spells: SpellsStore.create({data: []}),
            spellCards: SpellCardsStore.create({data: []}),
        });
        //fill store
        appData?.armies.forEach((item) => {store.armies.add(item)});
        appData?.factions.forEach((item) => {store.factions.add(item)});
        appData?.models.forEach((item) => {store.models.add(item)});
        appData?.cards.forEach((item) => {store.cards.add(item)});
        appData?.cardTypes.forEach((item) => {store.cardTypes.add(item)});
        appData?.keywords.forEach((item) => {store.keywords.add(item)});
        appData?.weapons.forEach((item) => {store.weapons.add(item)});
        appData?.modelAdvantages.forEach((item) => {store.modelAdvantages.add(item)});
        appData?.weaponQualities.forEach((item) => {store.weaponQualities.add(item)});
        appData?.cardOptions.forEach((item) => {store.cardOptions.add(item)});
        appData?.cardAbilities.forEach((item) => {store.cardAbilities.add(item)});
        appData?.modelAbilities.forEach((item) => {store.modelAbilities.add(item)});
        appData?.weaponAbilities.forEach((item) => {store.weaponAbilities.add(item)});
        appData?.editions.forEach((item) => {store.editions.add(item)});
        appData?.spells.forEach((item) => {store.spells.add(item)});
        appData?.spellCards.forEach((item) => {store.spellCards.add(item)});

        store.application.parseUrl();
    }
    if (snapshot) applySnapshot(store, snapshot);
    return store;
};

export default initStore;