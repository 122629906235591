import React from 'react';
import {inject, observer} from "mobx-react";
import Collapse from '@mui/material/Collapse';
import {useNavigate} from 'react-router-dom';

const Factions = ({store}) => {

    const navigate = useNavigate();

    if (store.editions?.selected?.factions?.length) {
        return (
            <div className="main-menu-part">
                <h3 className="editions-title">Choose faction:</h3>
                <div className="factions-holder">
                    {store.editions.selected.factions.map((faction, index) => (
                        <div className="faction-item-holder" key={index}>
                            <div className="faction-item"
                                 onClick={() => {
                                     if (store.factions.selectedID === faction.guidStr) {
                                         navigate(`/cards/${store.editions.selected.guidStr}`)
                                     } else {
                                         navigate(`/cards/${store.editions.selected.guidStr}/${faction.guidStr}`)
                                     }
                                 }}
                            >
                                <img className="faction-bg-img"
                                     src={`/CustomMedia/Factions/${faction.fullName.toLowerCase()}.jpg`}
                                     alt={faction.fullName}
                                />
                                <div className="faction-item-content-additional-bg"/>
                                <div className="faction-item-content">
                                    <img className="faction-logo-img"
                                         src={faction.iconColorPath}
                                         alt={faction.fullName}
                                    />
                                    <div className="faction-item-name">{faction.fullName}</div>
                                </div>
                            </div>
                            <Collapse in={(faction.guidStr === store.factions?.selected?.guidStr)} timeout="auto">
                                <div className="armies-holder">
                                    {store.armies.armiesInFaction(faction.guidStr).map((army, armyIndex) => (
                                        <div className="army-item"
                                             key={armyIndex}
                                             onClick={() => {
                                                 store.armies.selectArmy(army.guidStr);
                                                 navigate(`/cards/${store.editions.selected.guidStr}/${faction.guidStr}/${army.guidStr}`);
                                             }}
                                             style={{
                                                 backgroundColor: faction.hexColor,
                                                 borderColor: faction.hexColor2,
                                             }}
                                        >
                                            <div className="army-item-name">{army.fullName}</div>
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
};

export default inject('store')(observer(Factions));
