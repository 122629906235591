import {getRoot, types} from 'mobx-state-tree';

const EditionModel = types
    .model('EditionModel', {
        dataBundleId: types.string,
        deleted: false,
        desc: types.array(types.string),
        guidStr: types.identifier,
        isLive: types.boolean,
        metadata: types.maybeNull(types.string),
        name: types.array(types.string),
    }).views(self => ({
        get fullName() {
            return self.name[0];
        },
        get description() {
            let descriptionTxt = "";
            self.desc?.forEach(text => {
                descriptionTxt = `${descriptionTxt} ${text}`;
            });
            return descriptionTxt.trim();
        },
        get armies() {
            return getRoot(self).armies.data.filter(item => item.editionId === self.guidStr);
        },
        get factions() {
            let retArr = [];
            let factionIdsArray = [];
            self.armies.map((army) => {
                if (!factionIdsArray.includes(army.factionId)) {
                    factionIdsArray.push(army.factionId);
                    retArr.push(getRoot(self).factions.getByGuid(army.factionId));
                }
                return true;
            });
            retArr.sort((a, b) => a.fullName.localeCompare(b.fullName))
            return retArr;
        },
    }));

export default EditionModel;

