import React from 'react';
import {inject, observer} from "mobx-react";
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Editions = ({store}) => {

    const navigate = useNavigate();

    if (store.editions.data.length) {
        return (
            <div className="main-menu-part">
                <h3 className="editions-title">Choose edition:</h3>
                <div className="editions-holder">
                    {store.editions.data.map((edition, index) => (
                        <Card key={index}
                              className="edition-item"
                              onClick={() => navigate('/cards/'+edition.guidStr)}
                        >
                            <CardHeader
                                title={edition.fullName}
                            />
                            <CardMedia
                                component="img"
                                image={`/CustomMedia/Editions/${edition.fullName.toLowerCase()}.jpg`}
                                alt={edition.fullName}
                            />
                            <CardContent className="edition-description">
                                <Typography variant="body2">
                                    {edition.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        );
    }
};

export default inject('store')(observer(Editions));
