import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import Collapse from '@mui/material/Collapse';
import {useLocation, useNavigate} from "react-router-dom";
import CardResizersAndMath from "../card/card-resizers-and-math";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Drawer from '@mui/material/Drawer';

import CardInMenu from './menu-card';
import ModelInMenu from './menu-model';

import packageJson from '../../../package.json';


const priority = [
    "Warcasters",
    "Warlocks",
    "Infernal Masters",
    "Warjacks",
    "Warbeasts",
    "Horrors",
    "Battle Engines",
    "Monstrosities",
    "Structures",
    "Solos",
    "Units",
    "Command Attachments",
    "Weapon Attachments",
    "Defenses",
    "Terrain Cards",
    "Objective Cards",
]



const CardsMenu = ({store, type, cardGroups}) => {

    const [open, setOpen] = useState(true);

    const toggle = () => {
        setOpen(!open);
    };

    if (cardGroups[type]) {
        return (
            <div className="menu-cards-list">
                <div className="menu-card-type-title" onClick={toggle}>
                    <p>{type}</p>
                    <div className="menu-card-type-status">{open ? "▼" : "▲"}</div>
                </div>
                <Collapse in={open} timeout="auto">
                    <>
                        {cardGroups[type].map((card, index) =>
                            <div key={index}>
                                <CardInMenu card={card} />
                                {card.companions.length > 0 && (
                                    <div className="menu-card-companions-holder">
                                        {card.companions.map((companion, index) =>
                                            <CardInMenu
                                                card={companion}
                                                key={index}
                                                additionalClass={store.armies.selected.includedCardIds.includes(card.guidStr) ? "menu-card-model-info_mercenary" : null}/>
                                        )}
                                    </div>
                                )}
                                {card.models.length > 1 && (
                                    <div className="menu-card-companions-holder">
                                        {card.models.map((model, index) =>
                                            <ModelInMenu
                                                card={card}
                                                model={model}
                                                key={index}
                                                additionalClass={store.armies.selected.includedCardIds.includes(card.guidStr) ? "menu-card-model-info_mercenary" : null}/>
                                        )}
                                    </div>
                                )}
                                {card.grantedModels.length > 0 && (
                                    <div className="menu-card-companions-holder">
                                        {card.grantedModels.map((model, index) =>
                                            <ModelInMenu
                                                card={card}
                                                model={model}
                                                key={index}
                                                additionalClass={store.armies.selected.includedCardIds.includes(card.guidStr) ? "menu-card-model-info_mercenary" : null}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                </Collapse>
            </div>
        )
    } else return null;
}

const ModelCards = ({store, openPopup, printRef}) => {

    const [theme, setTheme] = useState('theme01');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `/Themes/${theme}/theme.css?v=` + packageJson.version;
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };
    }, [theme]);

    const handleThemeChange = (event) => {
        setTheme(event.target.value);
    };

    const applicationSelectedPart = location.pathname.split('/')[1];

    return (
        <div className="model-cards-list-holder">
            <div className="model-cards-menu-holder">
                {priority.map((cardType, index) =>
                    <CardsMenu
                        store={store}
                        type={cardType}
                        cardGroups={store.armies.selected.groupedCards}
                        key={index}
                    />
                )}
            </div>
            {(!store.application.isMobile && store.cards.selectedID) && (
                <div className="card-preview">
                    <div className="card-preview-inner">
                        <CardResizersAndMath printRef={printRef}/>
                    </div>
                    <div className="card-preview-actions">
                        <Button onClick={openPopup} variant="contained">Render image</Button>
                        {(store.cards.selected.spellRackOptions.length > 0) &&
                            <Button onClick={store.cards.selected.toggleSpellRack} variant="contained">{`${store.cards.selected.needSpellRack ? "Remove" : "Add"} cards from spell rack`}</Button>
                        }
                        <Button onClick={store.cards.selected.toggleSeparateHealth} variant="contained">{`${store.cards.selected.needSeparateHealthPage ? "Remove" : "Add"} separate page for health`}</Button>
                        <Select
                            variant="standard"
                            labelId="theme-select-label"
                            id="demo-simple-select"
                            value={theme}
                            label="Theme"
                            onChange={handleThemeChange}
                        >
                            <MenuItem value="theme01">Default theme</MenuItem>
                            {/*<MenuItem value="theme02">AI theme</MenuItem>*/}
                            <MenuItem value="theme03">Old-school MK2 Theme</MenuItem>
                        </Select>
                    </div>
                </div>
            )}
            {store.application.isMobile && (
                <Drawer
                    anchor={'right'}
                    open={!!store.cards.selectedID}
                    onClose={() => {
                        navigate(`/${applicationSelectedPart}/${store.editions.selectedID}/${store.factions.selectedID}/${store.armies.selectedID}`);
                    }}
                >
                    <div className="card-preview-inner-mobile">
                        <CardResizersAndMath/>
                    </div>
                </Drawer>
            )}
        </div>
    );

};

export default inject('store')(observer(ModelCards));
