import React, {} from 'react';


//For single model with a healthBars
const HealthBlocks = ({number, withInactive = true}) => {

    const blocks = [];
    let n = number;
    let boxInLine = 10;
    let j = 0;
    do {
        const divs = [];
        let q = n;
        if (q > boxInLine) {
            q = boxInLine;
        }
        for (let i = 1; i <= boxInLine; i++) {
            j++;
            if (i <= q) {
                divs.push(<div className="card-health-box" key={i}>{(j === number) && '♥'}</div>);
            } else if (i > q && withInactive) {
                divs.push(<div className="card-health-box-inactive" key={i}></div>);
            }
        }
        blocks.push(
            <div className="card-health-box-line" key={Math.floor(Math.random() * 100000)}>
                {divs}
            </div>
        );
        n = n - 10;
    } while (n > 0);

    return (
        <>
            {blocks}
        </>
    );
};

export default HealthBlocks;
