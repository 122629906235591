import React from 'react';
import {inject, observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import CardStats from '../card/card-stats';



const ModelInMenu = ({store, card, model, additionalClass=null}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const applicationSelectedPart = location.pathname.split('/')[1];
    const classGenerator = (model) => {
        let classnameVar = 'menu-card-model-info';
        if (model.guidStr === store.models.selectedID) {
            classnameVar += ' menu-card-model-info_active';
        }
        if (store.armies.selected.includedCardIds.includes(model.guidStr)) {
            classnameVar += ' menu-card-model-info_mercenary';
        }
        if (additionalClass) {
            classnameVar += ' '+additionalClass;
        }
        return classnameVar;
    }

    return (
        <div className={classGenerator(model)}
             onClick={() => {
                 navigate(`/${applicationSelectedPart}/${store.editions.selectedID}/${store.factions.selectedID}/${store.armies.selectedID}/${card.guidStr}/${model.guidStr}`);
             }
             }
        >
            <div className="menu-card-model-img-holder">
                <img className="menu-card-model-img"
                     src={card.portraitPath}
                     alt={card.fullName}
                />
            </div>
            <div className="menu-card-model-summary">
                <div className="menu-card-model-name">
                    {model.fullName}
                </div>
                {model && <CardStats model={model}/>}
            </div>
        </div>
    );

};

export default inject('store')(observer(ModelInMenu));
