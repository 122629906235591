import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import initStore from './store';
import { Provider } from 'mobx-react';
import CssBaseline from '@mui/material/CssBaseline';

import './index.css';
import App from './App';

const store = initStore();

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <App />
          </ThemeProvider>
      </Provider>
  </React.StrictMode>
);

