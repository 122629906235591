import React, {useRef} from 'react';
import {inject, observer} from "mobx-react";
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { toJpeg } from 'html-to-image';

import AppHeaderBar from './appbar';

import CardComponent from "./components/card";
import MainMenu from "./components/menu/menu-main";
import Editions from "./components/menu/menu-editions";
import Factions from "./components/menu/menu-factions";
import ModelCards from "./components/menu/menu-models";


import './menu.css';


const App = ({store}) => {

    const [cardInfoOpen, setCardInfoOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const printRef = useRef();

    const openPopup = () => {
        setCardInfoOpen(true);
        setLoading(true);
        setTimeout(() => {
            printRef.current.classList.add("printing");
        }, 1);
        setTimeout(() => {
            // toJpeg(printRef.current, { cacheBust: true, width: 800, height: 600})
            toJpeg(printRef.current, { cacheBust: true})
                .then((dataUrl) => {
                    setLoading(false);
                    let img = new Image();
                    img.id = "preview-img"
                    img.src = dataUrl;
                    document.getElementById("cardRef").appendChild(img);
                    printRef.current.classList.remove("printing");
                })
                .catch(function (error) {
                    setLoading(false);
                    console.error('oops, something went wrong!', error);
                });
        }, 500);
    }


    const closePopup = ()=> {
        setCardInfoOpen(false);
    }

    const handleSave = () => {
        const link = document.createElement('a');
        link.href = document.getElementById("preview-img").src;
        link.download = `${store.cards.selected.model.fullName}.jpg`;
        link.style = 'display: none';
        link.click();
        link.remove();
    };

    return (
        <Router>
            <div className="App warmachine_app">
                <AppHeaderBar openPopup={openPopup} />
                <CardComponent cardInfoOpen={cardInfoOpen}
                               openPopup={openPopup}
                               closePopup={closePopup}
                               handleSave={handleSave}
                               printRef={printRef}
                               loading={loading}
                />
                <div className="app-body">
                    <Routes>
                        <Route path="/cards/:editionId/:factionId/:armyId/:cardId?/:modelId?" element={
                            <ModelCards
                                openPopup={openPopup}
                                printRef={printRef}
                            />}
                        />
                        <Route path="/cards/:editionId/:factionId?" element={<Factions/>} />
                        <Route path="/cards" element={<Editions/>} />
                        <Route path="/" element={<MainMenu />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default inject('store')(observer(App));
