import {types} from 'mobx-state-tree';

//"guidStr":"0693f532-657a-45bc-b534-79f6ca32e871","name":["Lesser Horror","","","","",""],"isLive":true,"metadata":"","deleted":false,"helpTermIds":[],"iconPath":""

const KeywordModel = types
    .model('KeywordModel', {
        guidStr: types.identifier,
        helpTermIds: types.maybeNull(types.array(types.string)),
        iconPath: types.maybeNull(types.string),
        metadata: types.maybeNull(types.string),
        name: types.array(types.string),
    }).views(self => ({
        get fullName() {
            return self.name[0];
        },
    }));

export default KeywordModel;