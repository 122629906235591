import { types, getRoot } from 'mobx-state-tree';

const MiniatureModel = types
    .model('MiniatureModel', {
        guidStr: types.identifier,
        baseSize: types.string,
        modelAbilityIds: types.maybeNull(types.array(types.string)),
        modelAbilitySorting: types.maybeNull(types.array(types.string)),
        modelAdvantageIds: types.maybeNull(types.array(types.string)),
        name: types.array(types.string),
        statAAT: types.string,
        statARC: types.string,
        statARM: types.string,
        statCTRL: types.string,
        statDEF: types.string,
        statESS: types.string,
        statFURY: types.string,
        statMAT: types.string,
        statRAT: types.string,
        statSPD: types.string,
        statTHR: types.string,
        weaponIds: types.maybeNull(types.array(types.string)),
        weaponSorting: types.maybeNull(types.array(types.string))
    })
    .views(self => ({
        get fullName() {
            return self.name[0];
        },
        get weapons() {
            let weaponArray = [];
            self.weaponSorting.forEach(item => {
                let id = item.split(':')[1];
                getRoot(self).weapons.getByGuid(id) && weaponArray.push(getRoot(self).weapons.getByGuid(id));
            })
            return weaponArray;
        },
        get abilities() {
            let abilitiesArray = [];
            self.modelAbilitySorting.forEach(item => {
                let id = item.split(':')[1];
                getRoot(self).modelAbilities.getByGuid(id) && abilitiesArray.push(getRoot(self).modelAbilities.getByGuid(id));
            })
            return abilitiesArray;
        },
        get advantages()  {
            let advantagesArray = [];
            self.modelAdvantageIds.forEach(id => {
                getRoot(self).modelAdvantages.getByGuid(id) && advantagesArray.push(getRoot(self).modelAdvantages.getByGuid(id));
            });
            advantagesArray.sort((a, b) => a.displayPriority > b.displayPriority ? 1 : -1);
            return advantagesArray;
        },
        get uniqueWeaponQualities() {
            let arr = [];
            let retArr = [];
            self.weapons.forEach(weapon => {
                weapon.weaponQualityIds.forEach(id => {
                    if (!arr.includes(id)) {
                        arr.push(id)
                    }
                });
            });
            arr.forEach(id => {
                retArr.push(getRoot(self).weaponQualities.getByGuid(id));
            });
            retArr = retArr.sort((a, b) => a.displayPriority > b.displayPriority ? 1 : -1);
            return retArr;
        },
        get uniqueWeaponAbilities() {
            let arr = [];
            let retArr = [];
            self.weapons.forEach(weapon => {
                weapon.abilities.forEach(ability => {
                    if (!arr.includes(ability.guidStr)) {
                        retArr.push(ability);
                        arr.push(ability.guidStr);
                    }
                });
            });
            return retArr;
        }
    }));

export default MiniatureModel;
