import React from 'react';
import CardSpell from './card-spell';
import {inject, observer} from "mobx-react";

function chunk (arr, len) {
    var chunks = [],
        i = 0,
        n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}


const CardSpellRackPart = ({store, refs}) => {


// Optionally, you can do the following to avoid cluttering the global namespace:
    Array.chunk = chunk;
    let spellSchunks = chunk(store.cards.selected.faction.spells,5);

    let ret=[];

    spellSchunks.map((spellChunk, index) => (
        ret.push(
            <div className="card-content" key={index}>
                <div className="card-background"/>
                <div className="card-border-2"/>
                <div className="card-info-part">
                    <div className="card-info-part-card-name">
                        {store.cards.selected.faction.fullName} Spell Rack
                    </div>
                    <div id="card-spells-part"
                         className="card-info-part-spells"
                         ref={refs[index]}
                         style={{fontSize: `${store.application.cardSpellRackTextSize}px`}}
                    >
                        {spellChunk.map((spell, index) => (
                            <CardSpell spell={spell} key={index}/>
                        ))
                        }
                    </div>
                </div>
            </div>
        ))
    )

    return ret;
};

export default inject('store')(observer(CardSpellRackPart));
