import {getRoot, types} from 'mobx-state-tree';

const ApplicationStore = types.model('ApplicationStore', {
    cardZoom: types.maybeNull(types.number),
    cardSpellTextSize: types.maybeNull(types.number),
    cardSpellRackTextSize: types.maybeNull(types.number),
    cardAbilitiesTextSize: types.maybeNull(types.number),
    cardWeaponsTextSize: types.maybeNull(types.number),
    cardWeaponsTextSize2: types.maybeNull(types.number),
    defaultTextSize: types.maybeNull(types.number),
    abilitiesBlockSize: types.maybeNull(types.number),
    weaponsBlockSize: types.maybeNull(types.number),
    maxInfoPageSize: 2050,
    weaponBlockSizeIterations: 0,
    isMobile: false,
}).actions(self => ({
    parseUrl() {
        let pathArray = window.location.pathname.split('/');
        getRoot(self).editions.selectEdition(pathArray[2] ? pathArray[2] : null);
        getRoot(self).factions.selectFaction(pathArray[3] ? pathArray[3] : null);
        getRoot(self).armies.selectArmy(pathArray[4] ? pathArray[4] : null);
        getRoot(self).cards.selectCard(pathArray[5] ? pathArray[5] : null);
        getRoot(self).models.selectModel(pathArray[6] ? pathArray[6] : null);
    },
    setZoom(n) {
        self.cardZoom = n;
    },
    setCardSpellTextSize(n) {
        self.cardSpellTextSize = n;
    },
    setCardSpellRackTextSize(n) {
        self.cardSpellRackTextSize = n;
    },
    setCardAbilitiesTextSize(n) {
        self.cardAbilitiesTextSize = n;
    },
    setCardWeaponsTextSize(n) {
        self.cardWeaponsTextSize = n;
    },
    setCardWeaponsTextSize2(n) {
        self.cardWeaponsTextSize2 = n;
    },
    setAbilitiesBlockSize(n)   {
        self.abilitiesBlockSize = n;
    },
    setWeaponsBlockSize(n)   {
        self.weaponsBlockSize = n;
    },
    processInitialWeaponSize(n) {
        if ((self.weaponBlockSizeIterations===0)&&(n>(self.maxInfoPageSize+200))) {
            console.log('here',n,'case 1');
            self.weaponBlockSizeIterations++;
            getRoot(self).cards.selected.setHelperOnSeparatePage(true);
        } else if ((self.weaponBlockSizeIterations===1)&&(n>(self.maxInfoPageSize+200))) {
            console.log('here',n,'case 2');
            getRoot(self).cards.selected.setNeedNewPageForWeaponAbilities(true);
            getRoot(self).cards.selected.setHelperOnSeparatePage(false);
            self.weaponBlockSizeIterations++;
        } else if ((self.weaponBlockSizeIterations===2)&&(n>(self.maxInfoPageSize+200))) {
            getRoot(self).cards.selected.setHelperOnSeparatePage(true);
        } else {
            self.weaponBlockSizeIterations = 0;
        }
    }
})).views(self => ({
}));

export default ApplicationStore;
